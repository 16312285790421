<section class="container">

  <h3 class="font21 my-5 font-bold">Upload Your Content</h3>

    <div class="my-3 drop-image-message p-3 w-100">
    <p class="mb-1 font14 font-bold">RECOMMENDATIONS:</p>
      <p class="mb-1 font12 ml-3">Photos in the category <b>Business, Happy Moments, Party, Food</b> and <b>Education</b> are in demand right now.</p>
  </div>


  <div class="card">


    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="false"><p
      style="color: white"> Uploading... </p></ngx-spinner>

    <!-- in app.component.html -->
    <ngx-dropzone (change)="onSelect($event)" [expandable]="true" [multiple]="true">
      <ngx-dropzone-label>Drag and Drop an image</ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                                  [removable]="true" (removed)="onRemove(f)">
        <!--      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>-->
      </ngx-dropzone-image-preview>
    </ngx-dropzone>

  </div>
  <mat-progress-bar mode="determinate" *ngIf="loading" [value]="uploadProgress"></mat-progress-bar>


  <div class="p-3 w-100 border text-right sm-text-left my-4">
    <span class="mr-2" *ngIf="loading">Uploaded {{count}} of {{total}}</span>
    <button class="bg-red btn px-4 py-2 text-white border-radius opacity" (click)="uploadImages()" [disabled]="loading">Next

      <mat-spinner
        style="color:white !important;float: right"
        *ngIf="loading"
        [diameter]="20"
        color="#FFFF"
      ></mat-spinner>
    </button>
  </div>


  <div class="row mt-3 mb-5">
    <div class="col-lg-4 col-sm-12">
      <div class="px-4">
        <p class="font18">STEP 1</p>
        <p class="font14">Drag and drop or click and select images from your pc then click the "Next" button.
<!--          <span class="text-blue">Learn more.</span>-->
        </p>
      </div>
    </div>
    <div class="col-lg-4 col-sm-12">
      <div class="px-4 line-x">
        <p class="font18">STEP 2</p>
        <p class="font14">
          Describe the images, set tags and wait for your earnings.
        </p>
      </div>
    </div>
    <div class="col-lg-4 col-sm-12">
      <div class="px-4">
        <p class="font18">Need more help?</p>
        <p class="font14">Use the live chat at the bottom of your screen.</p>
      </div>
    </div>
  </div>
</section>
<section class="py-5 bg-grey">

  <section class="my-5 container">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <h3 class="font58 font-bold mb-5">
          F.A.Qs
        </h3>
        <div class="accordion" id="accordionExample">
          <div id="headingOne">
            <div class="my-4">
              <p
                class="text-brown font20 font-bold click"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is Orbzone?
              </p>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="font18 text-grey">
                Orbzone is a service that provides unlimited downloads of thousands of afrocentric image assets
              </div>
            </div>
          </div>
          <div id="headingTwo">
            <div class="my-4">
              <p
                class="text-brown font20 font-bold click"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Do i need to pay royalties?
              </p>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="font18 text-grey">
                No you don't. All images are royalty-free
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="bg-white p-4">
              <img src="assets/img/phone.png" />
              <h4 class="font21 font-bold mt-3 mb-3 text-blue">Call Us</h4>
              <p class="text-grey font13">
                Place a call to our contact centre for quick answers to all your
                questions.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="bg-white p-4">
              <img src="assets/img/email.png" />
              <h4 class="font21 font-bold mt-3 mb-3 text-blue">Send an Email</h4>
              <p class="text-grey font13">
                If you have further enquiries send us an email to support@orbzone.io
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
