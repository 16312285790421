import { Component, OnInit } from '@angular/core';
import {User} from '../../models/User';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public email;
  public phone;
  public firstname;
  public surname;
  public dob;
  public gender;
  public brand_name;
  public address;
  public password;
  public user:User = new User();
  public loading:boolean = false;

  constructor(
    public userService:UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router:Router,
  ) {

  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  }
  signUp(){
    let data = this.user;
    this.loading = true;
    this.userService.signUp(data).subscribe((success:any)=>{
      this.loading = false;

      this.toastr.success(success.message);
      this.router.navigate(['/sign-in']);

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }

}
