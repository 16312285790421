import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from './pages/signup/signup.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { imageDetailsComponent } from './pages/image-details/image-details.component';
import { SubcriptionComponent } from './pages/subcription/subcription.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { ContributorsComponent } from './pages/contributors/contributors.component';
import { ClientSignupComponent } from './user/client-signup/client-signup.component';
import {PurchaseGuard} from './purchase.guard';
import {FaqsComponent} from './faqs/faqs.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'sign-in', component: LoginComponent},
  {path: 'sign-up', component: SignupComponent},
  {path: 'search', component: SearchComponent},
  {path: 'subscription', component: SubcriptionComponent},
  {path: 'purchase/:slug/:imid', component: PurchaseComponent, canActivate: [PurchaseGuard]},
  {path: 'verify-email', component: VerifyEmailComponent},
  {path: 'terms', component: TermsConditionComponent},
  {path: 'image/:slug/:imid', component: imageDetailsComponent},
  {path: 'contributors', component: ContributorsComponent},
  {path: 'client-signup', component: ClientSignupComponent},
  {path: 'faqs', component: FaqsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
