<app-navbar></app-navbar>
<section class="py-5 my-5 container purchase">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <div class="row" *ngIf="image">
    <div class="col-lg-7 col-sm-12">
      <div class="price-cards py-4">
        <h3 class="font30 font-bold px-4">Invoice details</h3>
        <form action="" class="line-under px-4 pb-5 pt-2">
          <div class="row">
            <div class="col-lg-6 col-sm-12 my-2">
              <label class="font14 font-bold" for="firstname">First Name</label>
              <input
                type="text"
                disabled
                [(ngModel)]="userService.user.firstname"
                name="firstname"
                id="firstname"
                class="form-control common-input"
              />
            </div>
            <div class="col-lg-6 col-sm-12 my-2">
              <label class="font14 font-bold" for="surname">Surname</label>
              <input
                disabled
                type="text"
                name="surname"
                [(ngModel)]="userService.user.surname"
                id="surname"
                class="form-control common-input"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12 my-2" *ngIf="userService.user.phone">
              <label class="font14 font-bold" for="Country">Phone</label>
              <input
                disabled
                name="phone"
                [(ngModel)]="userService.user.phone"
                type="tel"
                id="Country"
                class="form-control common-input"
              />
            </div>
            <div class="col-lg-6 col-sm-12 my-2" *ngIf="userService.user.brand_name">
              <label class="font14 font-bold" for="City">Brand Name</label>
              <input
                [(ngModel)]="userService.user.brand_name"
                name="brand_name"
                type="text" id="City" disabled class="form-control common-input" />
            </div>
          </div>
          <div class="row" *ngIf="userService.user.address">
            <div class="col-lg-12 col-sm-12 my-2">
              <label class="font14 font-bold" for="Address">Address</label>
              <input
                disabled
                [(ngModel)]="userService.user.address"
                name="address"
                type="text"
                id="Address"
                class="form-control common-input"
              />
            </div>
          </div>

          <h2 class="font30 font-bold mt-3 mb-2">Payment details</h2>
          <p class="mt-2 font14 font-bold">Confirm payment</p>
          <div
            class="border d-flex justify-content-center w-100 text-center"
            style="height: 209px; flex-direction: column;"
          >
            <div>
              <h3 class="font-bold font36">&#x20A6; {{image.price}}</h3>
<!--              <p class="my]t-4 font14 font-bold">+ licence</p>-->
            </div>
          </div>
        </form>
        <div class="px-4 py-5">
          <div class="row m-1" *ngFor="let card of cards">
            <div class="col-lg-5 col-sm-12">
              <div class="d-flex justify-content-between">
                <div>
                  <img height="30" *ngIf="card.type == 'VISA'" src="assets/img/visa.png" alt=""/>
                  <img height="30" *ngIf="card.type == 'MASTERCARD'" src="assets/img/mastercard.png" alt=""/>

                </div>
                <div class="font-bold">
                  <span class="mr-2">***</span>
                  <span class="mr-2">***</span>
                  <span class="mr-2">***</span>
                  <span class="mr-2">{{card.last_four}}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-sm-12"></div>
            <div class="col-lg-6 col-sm-12 sm-mt">
              <button
                *ngIf="cardid === card.cardid"
                class="font14 w-100 text-white bg-green border-radius py-2"
                style="border:#41ADA0 solid .5px"
              >
                SELECTED
              </button>
              <button
                *ngIf="cardid !== card.cardid"
               (click)="selectCard(card.cardid)"
                class="font14 w-100 text-green bg-white border-radius py-2"
                style="border:#41ADA0 solid .5px"
              >
                Select Card
              </button>
            </div>
          </div>

          <div class="my-4">
            <label class="check-container">
              <input type="checkbox" value="true" (change)='onSelect($event)'/>
              <span class="checkmark" ></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey"
              >I agree to the
<!--              <span class="text-green">License Terms</span>-->
<!--              and-->
              <span routerLink="terms" class="text-green">
                Terms & Conditions
              </span>
            </span>
          </div>
          <button [disabled]="loading" class="bg-red btn text-white w-100 my-4" (click)="buyImage()">
            Complete Order
            <mat-spinner
              style="color:white !important;float: right"
              *ngIf="loading"
              [diameter]="20"
              color="#FFFF"
            ></mat-spinner>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-sm-12 my-auto">
      <div style="background:#ececee;" class="border-radius hidden">
        <div class="img-box" >
          <img [src]="image.preview" alt="" class="w-100" />
        </div>
        <p class="font-bold font14 p-3">
          {{image.name}}

          <br />

          <span *ngIf="!image.brand_name">By {{image.user.firstname}} {{image.user.surname}}</span>
          <span *ngIf="image.brand_name">By {{image.user.brand_name}}</span>
        </p>
      </div>
      <div class="mt-4 py-4 border-radius border" style="border-width: 4px !important;">
        <h4 class="font30 font-bold px-4">Invoice Summary</h4>
        <div class="font14 px-4 py-2" style="background: #ececee;">
            <p class="font-bold">Image: {{image.imid}}<br/>{{image.name}}</p>
            <div class="d-flex justify-content-between" *ngFor="let attribute of image.attributes">
                <p class="mb-0">{{attribute.name}}</p>
                <p class="mb-0">{{attribute.value}}</p>
            </div>

        </div>
        <div class="py-4 line-under px-4">
            <div class="d-flex justify-content-between">
                <p>Price</p>
                <p>&#x20A6;{{image.price}}</p>
            </div>
<!--            <div class="d-flex justify-content-between">-->
<!--                <p>Tax</p>-->
<!--                <p>NGN 500</p>-->
<!--            </div>-->
        </div>
        <div class="pb-3 pt-5 px-4">
            <div class="d-flex justify-content-between">
                <p>Total</p>
                <p>&#x20A6;{{image.price}}</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
