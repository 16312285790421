import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormBuilder, FormGroup} from '@angular/forms';

declare var getpaidSetup;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  public cards;
  public accounts;
  public accountid;
  public cardid;

  profileImage = 'assets/img/avatar.png';
  cardAdditionCharge :number = 10;
  loadingAccountName:boolean = false;
  loading:boolean = false;
  bank = '';
  account_number;
  account_name;
  reference;
  uploadForm : FormGroup;
  dob;
  initialDate;

  constructor(
    public userService: UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder : FormBuilder

  ) { }

  ngOnInit() {
    this.getUpdatedProfile();
    this.dob = new Date();
    this.uploadForm = this.formBuilder.group({
      image: ['']
    });
  }

  getUpdatedProfile(){
    let data = {

    };
    this.spinner.show();
    this.userService.profile(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.cards = success.data.cards;
      this.accounts = success.data.accounts;
      this.userService.user = success.data.user;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  selectAccount(acccountid){
    this.accountid = acccountid;
  }

  selectCard(cardid){
    this.cardid = cardid;
  }

  deleteCard(){

    let data = {
      "cardid" : this.cardid
    };
    this.spinner.show();
    this.userService.deleteCard(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.toastr.success(success.message);
      this.getUpdatedProfile();

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  deleteAccount(){
    let data = {
      "accountid" : this.accountid
    };
    this.spinner.show();
    this.userService.deleteAccount(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.toastr.success(success.message);
      this.getUpdatedProfile();
    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  resolveAccount(){
    let data = {
      "account_number" : this.account_number,
      "bank" : this.bank
    };
    this.loadingAccountName = true;
    this.spinner.show();

    this.userService.resolveAccount(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.loadingAccountName = false;

      this.account_name = success.data.name;
      this.getUpdatedProfile();
    },(error)=>{
      this.loadingAccountName = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }


  startCardAddition(){

    let quidpayscript:any = document.createElement('script');

    quidpayscript.setAttribute('src',this.userService.inlinePaymentUrl);

    document.head.appendChild(quidpayscript);

    let self = this;
    quidpayscript.onload = function(){

      self.loading = true;
      self.spinner.show();

      self.userService.startCardAddition().subscribe((success:any)=>{
        self.spinner.hide();

        self.loading = false;
        self.reference = success.data.reference;
        self.payWithQuidpay();

      },(error)=>{
        self.loading = false;
        self.spinner.hide();
        self.toastr.error(error.error.message);
      });

    };

  }

  finishCardAddition(){
    let data = {
      "reference" : this.reference
    };
    this.loading = true;
    this.spinner.show();

    this.userService.finishCardAddition(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.loading = false;
      this.toastr.success(success.message);
      this.getUpdatedProfile();
    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  selectProfileImageFile(event){
    let input = event.target;
    if (input.files && input.files[0]) {
      this.profileImage = input.files[0];
      this.saveProfileImage();
    }
  }


  choosePhoto(file: HTMLInputElement){
    file.click();
  }

  saveProfileImage(){
    this.uploadForm.get('image').setValue(this.profileImage);

    const formData = new FormData();
    formData.append('image', this.uploadForm.get('image').value);

    this.userService.loading = true;

    this.userService.saveProfileImage(formData).subscribe((success:any)=>{
      this.userService.loading = false;
      this.getUpdatedProfile();
      this.toastr.success(success.message);
      console.log(success.message);

    },(error)=>{
      this.userService.loading = false;

      this.toastr.error(error.error.message);
    });

  }



  payWithQuidpay() {
    let self = this;
  var x = getpaidSetup({
    PBFPubKey: this.userService.publicKey,
    customer_email: this.userService.user.email,
    amount: this.cardAdditionCharge,
    customer_phone: this.userService.user.phone,
    currency: "NGN",
    txref: this.reference,
    meta: [{
      metaname: "userid",
      metavalue: "123456"
    }],
    onclose: function() {},
    callback: function(response) {
      var txref = response.tx.txRef; // collect txRef returned and pass to a 					server page to complete status check.
      if (
        response.tx.chargeResponseCode == "00" ||
        response.tx.chargeResponseCode == "0"
      ) {
        self.finishCardAddition();
        // redirect to a success page
      } else {
        // redirect to a failure page.
      }

      x.close(); // use this to close the modal immediately after payment.
    }
  });

 }

  addBankAccount(){
    let data = {
      "account_number" : this.account_number,
      "bank" : this.bank,
      "account_name" : this.account_name
    };
    this.loading = true;
    this.spinner.show();

    this.userService.addBankAccount(data).subscribe((success:any)=>{
      this.spinner.hide();

      this.loading = false;
      this.accounts = success.data.accounts;

      this.account_name = success.data.name;
      this.toastr.success(success.message);
      this.getUpdatedProfile();

    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  dateChanged(){
    this.initialDate = this.dob;
    console.log(this.initialDate);
    console.log(this.dob);
  }

  editProfile(){
    let data = this.userService.user;

    data.dob = this.userService.getApiFormattedDate(this.dob);


    console.log(data);
    this.loading = true;
    this.spinner.show();

    this.userService.editProfile(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.toastr.success(success.message);
      this.getUpdatedProfile();

    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

}
