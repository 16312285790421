import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserService} from '../../services/user/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  search;
  constructor(
    public router:Router,
    public route: ActivatedRoute,
    public userService:UserService
  ) { }

  ngOnInit() {
    this.search = this.route.snapshot.queryParams.search;
  }

  searchForItem(){
    const queryParams: Params = { search: this.search };

    if(this.route.snapshot.url[0].path == 'search'){
      // the person is already on search page so no need to route
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
          replaceUrl : true
        });

    } else {
      // navigate to search page
      this.router.navigate(
        ['/search'],
        {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

    }
  }


}
