<app-navbar></app-navbar>

<header>
  <div class="header-text  text-white container">
    <h3 class="font64 font-bold">Terms of Use</h3>
    <p class="font18 my-3">
      IMPORTANT! PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY INCLUDE
      IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS AND OBLIGATIONS,
      INCLUDING LIMITATIONS ON OUR LIABILITY TO YOU.
    </p>
  </div>
</header>
<section class="my-5 container">
  <div class="row py-5">
    <div class="col-lg-3 col-sm-12">
      <h3 class="font36 font-bold text-green">Quick Summary</h3>
    </div>
    <div class="col-lg-9 col-sm-12 sm-mt">
      <p class="font20 font-light">



      <span>
        * We have full right to delete content we find unappealing to our brand/customers
      </span>
        <br>

      <span>
        * We would delete low quality resolution content
      </span>
        <br>

      <span>
        * We would delete non afrocentric content
      </span>
        <br>

      <span>
        * We would delete extremely nude pictures that relate more to porn
      </span>
        <br>

      <span>
        * We would delete reported pictures that have been proved to not be original content of poster
      </span>
        <br>

      <span>
        * We are not responsible for copyright infringement or intellectual property theft from any of our contributors
      </span>


      </p>



    </div>
  </div>
</section>
<section class="my-5 container sm-mt-none">
  <div class="row py-5 sm-pt-none">
    <div class="col-lg-3 col-sm-12">
      <h3 class="font36 font-bold text-green">Full Terms</h3>
    </div>
    <div class="col-lg-9 col-sm-12 sm-mt">
      <p class="font20 font-light">


        Please read these Terms and Conditions ("Terms") carefully before accessing or using the website at www.orbzone.io and all related websites, software, apps, and/or plug-ins (together the "Service") made available by Orbzone  ("Orbzone ", "us", "we" or "our").
<br><br>1. Acceptance of Terms
<br><br>1.1. Orbzone  operates the website www.orbzone.io ("Website"), a platform that allows you to post, link, store, share and otherwise make available certain information, text, graphics, photos, videos, or other material (together "Content"). Our goal is to help designers, bloggers and everyone who is looking for an image to find photos and other Content that you can use subject to and in compliance with these Terms.
<br><br>1.2. Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all photographers, visitors, users and others who access and/or use the Service.
<br><br>1.3. By accessing or using the Service, whether as a photographer, visitor or user of the Website, you agree to be bound by these Terms. These Terms serve to protect and safeguard your rights, the rights of other users, our rights and the rights of third parties in the course of operating the Website. If you do not agree to the terms of use, you must immediately stop using any part of the Service.
<br><br>1.4. We reserve the right to change or adapt these Terms at any time and without giving reasons with effect for the future. You will be notified of these changes before they take effect by posting them on the Website and should you have created a user account on our Website by notifying your registered e-mail address. You have the right to immediately cancel and terminate your account on our Website if you do not agree to the changes to the Terms. Changes shall be deemed approved by you if you continue to use the Service after the new Terms come into effect.
<br><br>1.5. The use of the Service is subject to the Terms in force at the time of use.
<br><br>2. Accounts and Registration
<br><br>2.1. You have the option of creating a user account on our Website so that you can use the additional functions of the Website, in particular for uploading photos and other Content. The opening of a user account can only take place with the agreement to these Terms.
<br><br>2.2. Upon registration, Orbzone and you enter into a contract for the use of the Website and the Services. There is no claim to the conclusion of this contract. Orbzone is entitled to refuse your registration without giving reasons.
<br><br>2.3. You may only register with Orbzone if you are 18 years of age or if you act with the consent of your parents or guardian to register under these Terms. Orbzone reserves the right to verify the consent of your parents or guardian. Therefore, you must provide an e-mail address of your parents or guardian when you register, so that we can obtain a declaration of consent from your parents or guardian.
<br><br>2.4. When you create an account with us, you must provide us with the information and data requested by Orbzone that is accurate, complete, and current at all times. If your data changes after registration, you are obliged to correct the information in your account immediately.
<br><br>2.5. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without authorization, or a name that is otherwise offensive, vulgar or obscene.
<br><br>2.6. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service. If you are not responsible for the misuse of your member account, you are not liable. You agree not to disclose your password to any third party. You must notify us immediately at support@Orbzone.io upon becoming aware of any breach of security or unauthorized use of your account.
<br><br>3. Data Protection
<br><br>3.1 Use of the Website and Service is subject to our Privacy Policy, which is available at www.orbzone.io/privacy-policy
<br><br>4. User Content
<br><br>4.1. When you upload any Content to the Service, you grant us a worldwide, non-exclusive, permanently, irrevocable, royalty-free license (with the right to sublicense) to reproduce, adapt, sell and modify (incl. translation), distribute, publicly perform, publicly display, broadcast, make available, store and archive and otherwise use such Content (in whole or in part) on and through the Service.
<br><br>4.2. You acknowledge and confirm that your Content will be made available to the public on and through the Service for personal and commercial use of third parties subject to these Terms while providing you with 75% of the charge made to the customer.
<br><br>4.3. You may not upload, post or transmit any Content that:
<br><br>•	Infringes any third party’s copyrights or other intellectual property rights, contract rights or any other rights of any person;
<br><br>•	Contains any pornographic, racist, defamatory, libelous or otherwise immoral, vulgar or obscene content;
<br><br>•	Depicts unlawful or violent, hateful or threatening or otherwise inappropriate acts;
<br><br>•	Offends, defames, harasses or otherwise damages Orbzone or any third party;
<br><br>•	Violates any law, statute, or regulation.
<br><br>4.4. We reserve the right to remove any Content at any time if we believe it’s defective, of poor quality, unappealing to our brand/customers or in violation of these Terms.
<br><br>4.5. You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) Orbzone will not need to obtain licenses from any third party or pay a compensation or royalties to any third party with respect to the Content; (iii) your Content does not infringe any third party rights (including in particular copyrights, neighboring rights, intellectual property rights, name rights, right of personality, rights of privacy, data rights or other property rights), and (iv) your Content complies with these Terms and all applicable laws.
<br><br>4.6. When you upload any Content to the Service you also authorize us under your copyrights to enforce any violations of the sublicense we grant in the Content to others. In other words, Orbzone is entitled to take appropriate measures to pursue the rights granted to us hereunder. You shall support us in the court or non-court assertion of the acquired rights, in particular by providing information, providing the necessary original documents and other documents, making or having made the necessary assignment of rights to Orbzone, as well as preparing any further declarations or documents which should be required or useful for the realization of the license granted by you to Orbzone.
<br><br>5. Intellectual Property; License to Users
<br><br>5.1. Subject to your compliance with these Terms, you may access and use the Website and Service.
<br><br>5.2. The Website and the Service are protected by copyright, trademark and/or other protective rights and are subject to copyright law and other protective laws ("Orbzone Rights"). Orbzone is the rightful owner or licensee of all rights to the Website and the Service. With the exception of the use of the Website and Service in accordance with these Terms, use of Orbzone Rights is only permitted with the prior written consent of Orbzone.
<br><br>5.3. Except for certain sponsored content (i.e. content from partners that you can buy from them by getting redirected to their website, hereinafter "Sponsored Content"), all Content made available for download on the Service can be used for a fee for personal and/or commercial purposes subject to some limitations as set out in these Terms. You do not need to, but you can, credit the photographer or owner of the Content or Orbzone.
<br><br>5.4. Orbzone License
<br><br>For all Content made available for download on the Service, that is not Sponsored Content, Orbzone grants you an irrevocable, perpetual, non-exclusive, royalty-free license to download, copy, modify, distribute, perform, and otherwise use the Content (this does not include Sponsored Content), including for commercial purposes, without attributing the photographer/ content owner or Orbzone  ("Orbzone License"). The Orbzone License does not include:
<br><br>•	the right to compile any Orbzone Content to replicate a similar or competing Service;
<br><br>•	the right to sell copies of the Orbzone Content without first updating, modifying, or otherwise incorporating new creative elements into the Content (i.e., selling unaltered copies of photos, videos or other content), including selling the Content as prints or printed on physical goods.
<br><br>5.5. While photographers and users that upload Content to our Website represent and warrant to us that they have all the rights therein and that the Content does not infringe any third party rights, Orbzone cannot reasonably monitor all Content uploaded to the Service. We therefore do not make any representations or guarantees for the rights granted hereunder to you.
<br><br>5.6. Be aware that, depending on your intended use of the Content, you may need the permission or consent of a third party (e.g. owner of a brand, identifiable person or author/rights holder of copyrightable work depicted in the Content).
<br><br>6. Restrictions on use of the Service and Content
<br><br>6.1. You represent and warrant to Orbzone that by using the Service you will NOT:
<br><br>•	impersonate any other person on the Services, e.g. a representative of Orbzone or any other person responsible for the Services, or impersonate any non-existent relationship with such persons;
<br><br>•	use the Service and/or Website for the storage, publication and/or transmission of "junk mails", chain letters, unsolicited bulk e-mails, etc., or for any other purpose. "("spamming");
<br><br>•	send any data or store any data on an Orbzone data carrier which, by its nature or nature (e.g. viruses), size or reproduction (e.g. spamming), is intended to impair or endanger the existence or operation of the Services and the Orbzone data network;
<br><br>•	make any electronic attacks of any kind on the Service or network; in particular, the use of computer programs to automatically read out data such as robots, spiders, etc. is prohibited;
<br><br>•	use or exploit any metatags or other hidden texts containing the name or trademark of Orbzone without the express written consent of Orbzone;
<br><br>•	collect, store or use any personal information, including member names and profiles, about other users;
<br><br>•	copy the look and feel of the Website.
<br><br>6.2. Furthermore, despite the Orbzone License provided to you by Orbzone hereunder you agree to NOT use any Orbzone Content made available via the Service (in whole or in part):
<br><br>•	to portray any person depicted in the Content (a "Person") in a way that a reasonable person would find offensive, including but not limited to depicting a Person: a) in connection with pornography, adult entertainment venues, escort services, dating services, or the like; b) in connection with the endorsement of products and services (i.e. statement made to the Person highlighting the benefits or recommending products/services to the public); c) in a political context, such as the promotion, advertisement or endorsement of any party, candidate, or elected official, or in connection with any political policy or viewpoint; d) as suffering from, or medicating for, a physical or mental ailment; or e) engaging in immoral or criminal activities;
<br><br>•	in a pornographic, defamatory, or deceptive context, or in a manner that could be considered libelous, obscene, or illegal;
<br><br>•	as a trademark, service mark, logo or other indication of origin, or as part thereof;
<br><br>•	in a manner that infringes upon any third party’s copyright, trademark or other intellectual property, or gives rise to a claim of deceptive advertising or unfair competition;
<br><br>•	falsely represent, expressly or by way of reasonable implication, that any Content was created by you or a person other than the copyright holder(s) of that Content;
<br><br>•	access, download, copy, modify, distribute, perform, or use any Content to create a similar or competitive service or to contribute the Content to an existing similar or competitive service.
<br><br>7. Violation of Terms and Termination of Use
<br><br>7.1. Orbzone reserves the right to reject the publication of Content at its own discretion and to delete Content already published.
<br><br>7.2. Orbzone further reserves the right to restrict or suspend your access to the Services in whole or in part at any time, without prior notice and excluding any claim for damages on your part ("Suspension"), if there are reasonable grounds for suspecting violations by you of these Terms and/or the applicable Conditions of Participation.
<br><br>7.3. If you prove within twelve (12) months after the Suspension that you have not violated the Terms the applicable Conditions of Participation, Orbzone will lift the Suspension. If such proof is not provided within the 12-month period, Orbzone is entitled to delete your member account including all its data.
<br><br>7.4. Orbzone expressly reserves the right to take legal action, in particular to assert claims for damages or to file criminal charges.
<br><br>8. Notice of Infringements
<br><br>8.1. Please let us know if you believe that any Content made available on and via the Service contains a violation of the law. The message can be sent by e-mail to the address support@orbzone.io.
<br><br>8.2. If you as the copyright owner or as its representative believe that your copyrights are infringed by the Content of users stored, published or transmitted on the Website, please notify us by e-mail to support@orbzone.io including the following information:
<br><br>•	a description of the rights you consider to have been infringed;
<br><br>•	a statement of the content you believe infringes your rights, preferably including a URL, and the part of the content you believe infringes the law;
<br><br>•	your address, telephone number and email address to contact you;
<br><br>•	a statement by you that, to the best of your knowledge and in good faith, you believe that the use in question has not been approved or is not authorized by the rights holder or its agent.
<br><br>9. Disclaimer of Warranty; Links to other third party sites
<br><br>9.1. The use of the Service is at your own risk. The Website and the Service are offered to you on an "as is" and "as available" basis. We do not warrant that the Website or the Services will always be uninterrupted, current, timely, secure, error-free or suitable for use for any particular purpose or to achieve any particular result.
<br><br>9.2. Orbzone does not guarantee that the content on the Website or on other linked sites is correct or complete. The content of the users does not reflect our opinions or views on life. The presence of links to other websites does not imply that Orbzone endorses or recommends the linked website. Orbzone has no influence on the contents of the linked external sites and is not responsible for them. We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit. Orbzone is also not responsible for the advertising of third parties published on the Website or for the goods or services offered therein.
<br><br>9.3. If Orbzone becomes aware of any violations of the law through content, links to third-party websites or advertising published on the website, Orbzone will immediately delete such content, links or advertising.
<br><br>9.4. Orbzone accepts no responsibility for errors, omissions, interruptions, deletions, defects, delays in operation or transmission, communication line failures or unauthorized access or theft, destruction or modification of user communications. Likewise, Orbzone is not liable for problems or technical malfunctions in connection with telephone networks or lines, online systems, servers or providers, computer equipment, software or for failures of e-mails or players due to technical problems or data jams on the internet and/or one of the Service, unless these are within the sphere of influence of Orbzone.
<br><br>10. Liability
<br><br>10.1. YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THIS WEB SITE AND THE MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION CONTAINED THEREIN.
<br><br>10.2. IN NO EVENT SHALL ORBZONE OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR ANY OTHER PECUNIARY LOSS) IN CONNECTION WITH ANY CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THIS WEB SITE, THE MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.
<br><br>10.3. IN ANY EVENT, THE TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PART OF THIS WEB SITE OR THE MATERIALS IN ANY MANNER WHATSOEVER SHALL BE LIMITED TO ONE THOUSAND FIVE HUNDRED (N1500.00) NIGERIAN NAIRA.
<br><br>10.4. The above limitations of liability also apply in favour of our employees, staff, representatives and agents.
<br><br>10.5. Claims for damages against Orbzone shall become statute-barred after twelve (12) months from the date of their creation, unless they are based on intentional or grossly negligent acts.
<br><br>11. Indemnification
<br><br>You agree to defend, indemnify and hold Orbzone and its affiliates harmless from and against any and all claims, damages, liabilities, losses or demands arising out of or in connection with (i) your use and access to the Website and/or Service; (ii) your violation of these Terms; (iii) infringement of third party rights (copyrights, trademark rights, personal rights, etc.) by you or your Content. The indemnity obligation includes the reasonable costs of legal defence, in particular lawyer's fees, necessary to defend against such claims.
<br><br>12. Choice of law, place of jurisdiction
<br><br>The law of the Federal Republic of Nigeria shall apply. For merchants, legal entities under public law and special funds under public law, the place of jurisdiction is Abuja.
<br><br>13. Miscellanous
<br><br>14.1. These Terms, together with the applicable Conditions of Participation, our Privacy Policy, and any other legal provisions published by us on the Website, constitute the sole agreement between you and Orbzone regarding your use of the Website and the Services.
<br><br>14.2. Should individual clauses of these Terms be wholly or partially invalid or should these Terms contain gaps, this shall not affect the validity of the remaining Terms.




      </p>
    </div>
  </div>
</section>
