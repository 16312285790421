<app-navbar></app-navbar>
<section class="px-5" (load)="loaded()">
  <ngx-spinner type="ball-atom"></ngx-spinner>

  <h4 class="font24 font-lato bold mt-5">
    <span class="light font-lato">Search our library of</span>
    {{total_images}}
    <span class="light font-lato">Images</span>
  </h4>
  <h6 class=" font-lato bold" *ngIf="filtered_images">
    {{filtered_images}}
    <span class="light font-lato">Image(s) match your search "{{search}}"</span>
  </h6>
  <span class="light font-lato">Displaying items {{from}} to {{to}}</span>
</section>
<section style="background-color: #fafafa; ">
  <div class="d-flex justify-content-between mb-5 px-5">
    <button class="font14 text-white font-lato bold bg-black btn" *ngIf='openSidebar' (click)='toggleSidebar()'>
      <span class="font20 mr-2">x</span>
      Hide filters
    </button>
    <button class="invisible font14 text-white font-lato bold bg-black btn" *ngIf='!openSidebar' (click)='toggleSidebar()'>
      <span class="font20 mr-2">+</span>
      Show filters
    </button>
    <select class="invisible">
      <option value="sort by popular">Sort by popular</option>
      <option value="sort">sort</option>
      <option value="sort">sort</option>
      <option value="sort">sort</option>
    </select>
  </div>
  <div class="active col-12"> <!-- add flex-page here when introducing filters -->
    <div class="d-none sidebar active">
      <div class="pl-4">
        <div class="line-under font14 font-bold pb-4">
          All Items / Stock photos
        </div>
        <div class="line-under py-3">
          <div class="my-1">
            <img src="assets/img/Group25.png" alt=""/>
            <span class="font14 font-bold ml-3">Refine by</span>
          </div>
          <div class="my-1">
            <span class="font14 font-bold ml-3">Categories</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Stock Photo</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Motion Graphics</span>
          </div>
        </div>
        <div class="line-under py-3">
          <div class="my-1">
            <span class="font14 font-bold ml-3">Orientation</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Landscape</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Portrait</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Square</span>
          </div>
        </div>
        <div class="line-under py-3">
          <div class="my-1">
            <span class="font14 font-bold ml-3">Size</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Original</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Large</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Medium</span>
          </div>
          <div class="my-1">
            <label class="check-container">
              <input type="checkbox"/>
              <span class="checkmark"></span>
            </label>
            <span class="font14 font-bold pl-4 ml-2 text-grey">Small</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row font14 bold font-lato search-body active">
      <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let image of images">
        <div class="bg-white my-4 border-radius box-shadow" [routerLink]="['/image',slugifyPipe.transform(image.name),image.imid]">
          <div class="img-box browse-image">
            <img [src]="image.preview" alt="" class='w-100'/>
          </div>
          <div class="p-3 d-flex align-items-center justify-content-between">
            <div>
              <p>{{image.name}}</p>
              <p *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>
              <p *ngIf="image.user.brand">By {{image.user.brand}}</p>
            </div>
            <div>
              <img src="assets/img/Group.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-paginator [page]="page" [pages]="pages" ></app-paginator>

  </div>


</section>
