<div class="pl-4 hidden">
  <div class="row" *ngIf="image">
    <div class="col-lg-8 col-sm-12">
<!--      <div class="box-shadow ">-->
<!--        <ul class="nav nav-pills" id="pills-tab" role="tablist">-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link font-bold font14 active"-->
<!--              id="submit-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#submit"-->
<!--              role="tab"-->
<!--              aria-controls="submit"-->
<!--              aria-selected="true"-->
<!--            >-->
<!--              To submit (1)-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link font-bold font14"-->
<!--              id="pending-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#pending"-->
<!--              role="tab"-->
<!--              aria-controls="pending"-->
<!--              aria-selected="false"-->
<!--            >-->
<!--              Pending (0)-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link font-bold font14"-->
<!--              id="reviewed-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#reviewed"-->
<!--              role="tab"-->
<!--              aria-controls="reviewed"-->
<!--              aria-selected="false"-->
<!--            >-->
<!--              Reviewed (0)-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link font-bold font14"-->
<!--              id="images-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#images"-->
<!--              role="tab"-->
<!--              aria-controls="images"-->
<!--              aria-selected="false"-->
<!--            >-->
<!--              Images-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item ml-auto">-->
<!--            <a-->
<!--              class="nav-link font-bold font14"-->
<!--              id="newest-tab"-->
<!--              data-toggle="pill"-->
<!--              href="#newest"-->
<!--              role="tab"-->
<!--              aria-controls="newest"-->
<!--              aria-selected="false"-->
<!--            >-->
<!--              Newest-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      <div class="my-5 d-flex">-->

<!--        &lt;!&ndash;  notice section &ndash;&gt;-->
<!--        <div class="p-3 w-90 font14 border-radius border">-->
<!--          Please add extra information about your image or update existing information.-->
<!--          <a href="#">Learn more.</a>-->
<!--        </div>-->
<!--        &lt;!&ndash; end notice &ndash;&gt;-->
<!--      </div>-->
      <section class="pb-5 container">
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="submit"
            role="tabpanel"
            aria-labelledby="submit-tab"
          >

            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 z-index-0">
                <div class="img-box image-details" align="center">
                  <img *ngIf="image" [src]="image.preview" alt="" />
                </div>
                <p class="font14 font-light mt-4 mb-2">
                  Royalty-free stock photo ID: {{image.imid}}
                </p>
                <p class="font18 font-bold" *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>
                <p class="font18 font-bold" *ngIf="image.user.brand">By {{image.user.brand}}</p>

              </div>

<!--              <div class="col-lg-3 col-sm-6">-->
<!--                <img src="assets/img/Group7.png" alt="" class="w-100" />-->
<!--                <p class="my-3 font-bold font14">-->
<!--                  Chinese food set. Asian Style fo.....-->
<!--                </p>-->
<!--              </div>-->
<!--              <div class="col-lg-3 col-sm-6">-->
<!--                <div class="p-r">-->
<!--                  <span class="select-image"><img src="assets/img/Group912.png" alt=""/></span>-->
<!--                  <img src="assets/img/Group9.png" alt="" class="w-100" />-->
<!--                  <p class="my-3 font-bold font14">-->
<!--                    Chinese food set. Asian Style fo.....-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pending"
            role="tabpanel"
            aria-labelledby="pending-tab"
          ></div>
          <div
            class="tab-pane fade"
            id="reviewed"
            role="tabpanel"
            aria-labelledby="reviewed-tab"
          ></div>
          <div
            class="tab-pane fade"
            id="images"
            role="tabpanel"
            aria-labelledby="images-tab"
          ></div>
          <div
            class="tab-pane fade"
            id="newest"
            role="tabpanel"
            aria-labelledby="newest-tab"
          ></div>
        </div>
      </section>
    </div>
    <div class="col-lg-4 col-sm-12">
      <div class="py-4 px-3 line-left">
        <h3 class="font24 mb-4">Update Image</h3>
        <p class="font-bold">Image type</p>
        <div class="row">
          <div class="col-6">
            <button class="btn bg-dark-grey w-100" [ngClass]="{'bg-grey' : type == 'photo' }" (click)="setType('photo')">
              Photo
            </button>
          </div>
          <div class="col-6">
            <button class="btn w-100" [ngClass]="{'bg-grey' : type == 'illustration' }" (click)="setType('illustration')">
              Illustration
            </button>
          </div>
        </div>
        <div class="my-3">
          <input
            [(ngModel)]="name"
            type="text"
            placeholder="Name"
            class="common-input w-100"
          />
          <textarea
            [(ngModel)]="description"
            rows="5"
            placeholder="Description"
            class="common-input w-100"
          ></textarea>
<!--          <span class="font12 float-right">0/200</span>-->
          <p class="font-bold mb-0 mt-1">Category</p>
          <select class="common-input w-100 text-grey my-3" [(ngModel)]="category">
            <option value="choose" disabled>Choose Category</option>
            <option value="Nature & Wildlife">Nature & Wildlife</option>
            <option value="Happiness">Happiness</option>
            <option value="Business">Business</option>
            <option value="Children">Children</option>
            <option value="Rural">Rural</option>
            <option value="Holiday">Holiday</option>
            <option value="Event">Event</option>
            <option value="School">School</option>
            <option value="Women">Women</option>
            <option value="Market">Market</option>
            <option value="Transportation">Transportation</option>
            <option value="Buildings">Buildings</option>
            <option value="Technology">Technology</option>
            <option value="People">People</option>
            <option value="Culture">Culture</option>
            <option value="Food">Food</option>
            <option value="Party">Party</option>
            <option value="Wedding">Wedding</option>
            <option value="Portrait">Portrait</option>
            <option value="Fashion">Fashion</option>
          </select>

          <p class="font-bold mb-0 mt-1">Location</p>
          <select class="common-input w-100 text-grey my-3" [(ngModel)]="location">
            <option value="choose" disabled>Choose Category</option>

            <option value="algeria">Algeria</option>
            <option value="angola">Angola</option>
            <option value="benin">Benin</option>
            <option value="botswana">Botswana</option>
            <option value="burkina Faso">Burkina Faso</option>
            <option value="burundi">Burundi</option>
            <option value="cameroon">Cameroon</option>
            <option value="cape-verde">Cape Verde</option>
            <option value="central-african-republic">Central African Republic</option>
            <option value="chad">Chad</option>
            <option value="comoros">Comoros</option>
            <option value="congo-brazzaville">Congo - Brazzaville</option>
            <option value="congo-kinshasa">Congo - Kinshasa</option>
            <option value="ivory-coast">Côte d’Ivoire</option>
            <option value="djibouti">Djibouti</option>
            <option value="egypt">Egypt</option>
            <option value="equatorial-guinea">Equatorial Guinea</option>
            <option value="eritrea">Eritrea</option>
            <option value="ethiopia">Ethiopia</option>
            <option value="gabon">Gabon</option>
            <option value="gambia">Gambia</option>
            <option value="ghana">Ghana</option>
            <option value="guinea">Guinea</option>
            <option value="guinea-bissau">Guinea-Bissau</option>
            <option value="kenya">Kenya</option>
            <option value="lesotho">Lesotho</option>
            <option value="liberia">Liberia</option>
            <option value="libya">Libya</option>
            <option value="madagascar">Madagascar</option>
            <option value="malawi">Malawi</option>
            <option value="mali">Mali</option>
            <option value="mauritania">Mauritania</option>
            <option value="mauritius">Mauritius</option>
            <option value="mayotte">Mayotte</option>
            <option value="morocco">Morocco</option>
            <option value="mozambique">Mozambique</option>
            <option value="namibia">Namibia</option>
            <option value="niger">Niger</option>
            <option value="nigeria">Nigeria</option>
            <option value="rwanda">Rwanda</option>
            <option value="reunion">Réunion</option>
            <option value="saint-helena">Saint Helena</option>
            <option value="senegal">Senegal</option>
            <option value="seychelles">Seychelles</option>
            <option value="sierra-leone">Sierra Leone</option>
            <option value="somalia">Somalia</option>
            <option value="south-africa">South Africa</option>
            <option value="sudan">Sudan</option>
            <option value="south-sudan">Sudan</option>
            <option value="swaziland">Swaziland</option>
            <option value="Sao-tome-príncipe">São Tomé and Príncipe</option>
            <option value="tanzania">Tanzania</option>
            <option value="togo">Togo</option>
            <option value="tunisia">Tunisia</option>
            <option value="uganda">Uganda</option>
            <option value="western-sahara">Western Sahara</option>
            <option value="zambia">Zambia</option>
            <option value="zimbabwe">Zimbabwe</option>

          </select>

          <p class="font-bold">Attributes</p>
          <div class="alert alert-secondary">
            Attributes are the technical properties of your image like dimensions etc.
          </div>

          <div class="my-3 row" *ngFor="let attribute of attributes;index as i">
            <div class="col-lg-5">
              <input type="text" placeholder="Name" [value]="attribute.name"  class="common-input w-90" />
            </div>
            <div class="col-lg-5 offset-1">
              <input type="text" placeholder="Value" [value]="attribute.value" class="common-input w-90" />
            </div>

            <div class="col-lg-1">
              <img class="delete-icon pointer" src="assets/img/delete.png" (click)="deleteAttribute(i)" alt=""/>
            </div>

          </div>

          <div class="my-3 row">
            <div class="col-6">
              <input type="text" placeholder="Name" [(ngModel)]="attributeName" class="common-input w-90" />
              <img src="assets/img/menu-dot.png" alt="" class="ml-2" />
            </div>
            <div class="col-6">
              <input type="text" placeholder="Value" (keyup.enter)="addAttribute()" [(ngModel)]="attributeValue" class="common-input w-90" />
            </div>
            <button
              (click)="addAttribute()"
              class="btn bg-red font-bold text-white w-100 mb-5 mt-4 download-image-details">
              Add Attribute
            </button>
          </div>
          <div class="my-5">
            <p class="font-bold">Tags</p>
            <div class="alert alert-secondary">
              Tags are keywords that relate to your image that would enable people discover it
            </div>
            <div class="my-4 keywords">
              <span
                *ngFor="let tag of tags; let i = index"
                class="py-2 px-3 border-radius border text-grey"
              >{{tag}}
              <i class="fa fa-times bg-red pointer"
                 (click)="deleteTag(i)"></i>
              </span>
            </div>
            <div class="mt-3 mb-1 row">
              <div class="col-12">
                <input type="text" name="tag" placeholder="Enter Tag" (keydown.enter)="addTag()" [(ngModel)]="tag"  class="common-input w-90 width-100" />
                <img src="assets/img/menu-dot.png" alt="" class="ml-2" />
              </div>
              <button
                (click)="addTag()"
                class="btn bg-red font-bold text-white w-100 mb-5 mt-4 download-image-details">
                Add Tag
              </button>
            </div>

            <div class="col-12 mb-2">
              <label>Publish Now?</label>
              <div class="row">
                <div class="col-6">
                  <button class="btn publish bg-dark-grey w-100" [ngClass]="{'bg-green' : publish == 'yes' }" (click)="setPublish('yes')">
                    Yes
                  </button>
                </div>
                <div class="col-6">
                  <button class="btn w-100" [ngClass]="{'bg-grey' : publish == 'no' }" (click)="setPublish('no')">
                    No
                  </button>
                </div>
              </div>

            </div>


            <div class="my-5">

              <button class="w-100 py-3 bg-red btn text-white"
                      [disabled]="loading"
                      (click)="updateImage()">Submit
                <mat-spinner
                  style="color:white !important;float: right"
                  *ngIf="loading"
                  [diameter]="20"
                  color="#FFFF"
                ></mat-spinner>

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
