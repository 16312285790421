import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-client-upload-content',
  templateUrl: './client-upload-content.component.html',
  styleUrls: ['./client-upload-content.component.css']
})
export class ClientUploadContentComponent implements OnInit {

  files: File[] = [];
  loading:boolean;
  uploadForm: FormGroup;

  uploadedImids = [];
  uploadProgress = 0;
  count = 0;
  total = 0;


  constructor(
    public userService: UserService,
    public toastr: ToastrService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public spinner: NgxSpinnerService

  ) { }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      image: ['']
    });

  }


  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadImages(){
    this.spinner.show();
    this.total = this.files.length;
    for(let i =0; i < this.files.length; i++){
      this.uploadForm.get('image').setValue(this.files[i]);

      const formData = new FormData();
      formData.append('image', this.uploadForm.get('image').value);

      this.loading = true;

      this.userService.addImage(formData).subscribe((success:any)=>{

        this.count++;
        this.uploadedImids.push(success.data.imid);

        this.uploadProgress = (this.uploadedImids.length / this.files.length) * 100;

        if(this.uploadedImids.length >= this.files.length){
          this.loading = false;
          this.router.navigate(['/user/dashboard'],{queryParams:{section : 'contributions'}});
        }


      },(error)=>{
        this.loading = false;
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });
    }


  }


}
