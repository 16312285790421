import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../user/user.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public userService : UserService,
    public toastr : ToastrService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.toastr.error("Something went wrong. Please try again.");

          } else {
            this.userService.handleAuthError(error);

            if(error.status === 500){
              this.toastr.error("Something went wrong. Please try again in a few minutes.");
            }

          }
          return throwError(error);
        })
      )
  }
}
