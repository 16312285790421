<div class="row col-12 justify-content-center">
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item"><a *ngIf="page - 1 > 0" class="page-link" (click)="goToPage(page - 1)" >Previous</a></li>
      <li class="page-item"><a  *ngIf="page - 1 > 0" class="page-link" (click)="goToPage(page - 1)">{{page - 1}}</a></li>
      <li class="page-item"><a  class="page-link active" (click)="goToPage(page)" >{{page}}</a></li>
      <li class="page-item"><a *ngIf="page + 1 <= pages" class="page-link" (click)="goToPage(page + 1)" >{{page + 1}}</a></li>
      <li class="page-item"><a *ngIf="page + 1 <= pages" class="page-link" (click)="goToPage(page + 1)">Next</a></li>
    </ul>
  </nav>
</div>
