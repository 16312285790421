import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserService} from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'orbzone';

  constructor(
    private router:Router,
    private userService:UserService
  ) {
    this.userService.setup();
  }


  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
