import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Params, Router} from '@angular/router';
import {SlugifyPipe} from '../../pipes/SlugifyPipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  loading:boolean = false;
  search = '';
  isFirstLoad = true;
  images = [];
  categories: any[] = [];
  page = 1;
  lastPage = 0;

  constructor(
    public userService:UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public router : Router,
    public slugifyPipe : SlugifyPipe

  ) { }

  ngOnInit() {
    if(this.userService.isLoggedIn)
      this.userService.getUpdatedProfile();
    this.searchForImages();
    this.getCategories();
  }

  getCategories(){

    this.userService.categories().subscribe((success:any)=>{
      this.spinner.hide();
      this.categories = success.data.categories;

    },(error)=>{
      this.userService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  searchForImages(){
    if(this.isFirstLoad)
    this.spinner.show();

    let data = {
      "name" : this.search
    };

    this.userService.searchForImages(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.isFirstLoad = false;
      this.userService.loading = false;
      this.images = success.data.images.data;
      this.lastPage = success.data.images.last_page;

    },(error)=>{
      this.userService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  loadMore(){
    console.log("load more called ")
    console.log(this.page)
    if(this.page < this.lastPage) {
      this.page = this.page + 1;


      if (this.isFirstLoad)
        this.spinner.show();

      let data = {
        "name": this.search,
        "page": this.page
      };

      this.userService.searchForImages(data).subscribe((success: any) => {
        this.spinner.hide();
        this.isFirstLoad = false;
        this.userService.loading = false;

        this.lastPage = success.data.images.last_page;

        for (let i = 0; i < success.data.images.data.length; i++) {
          this.images.push(success.data.images.data[i]);
        }


      }, (error) => {
        this.userService.loading = false;
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });

    }
  }

  searchForItem(){
    const queryParams: Params = { search: this.search };

    this.router.navigate(
      ['/search'],
      {
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
        replaceUrl : true
      });
  }


}
