import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {SlugifyPipe} from '../../pipes/SlugifyPipe';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  isFirstLoad:boolean = false;
  openSidebar = true;
  search;
  images;
  page;
  from;
  to;
  pages;
  filtered_images: number;
  total_images: number = 0;

  constructor(
    public userService:UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    public slugifyPipe : SlugifyPipe,
    public router : Router

  ) { }

  ngOnInit() {
    this.spinner.show();
    this.searchForImages();
    this.toggleSidebar();

    this.route.queryParams.subscribe(queryParams => {
      this.search = queryParams["search"];
      this.page = queryParams["page"];
      this.searchForImages();
    });

  }

  goToDetails(image){
    let slug = this.slugifyPipe.transform(image.name);
    this.router.navigate(['image',slug,image.imid]);
  }


  toggleSidebar(){

    var Sidebar = document.querySelector('.sidebar');
    var SearchBody = document.querySelector('.search-body');

    SearchBody.classList.toggle('active');
    Sidebar.classList.toggle('active');
    this.openSidebar= !this.openSidebar

  }

  loaded(){
    this.spinner.hide();
  }

  searchForImages(){
    if(this.isFirstLoad)
      this.spinner.show();

    let data = {
      "name" : this.search,
      "page" : this.page
    };

    this.userService.searchForImages(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.isFirstLoad = false;
      this.userService.loading = false;
      this.images = success.data.images.data;
      this.total_images = success.data.total_images;
      this.page = success.data.images.current_page;
      this.pages = success.data.images.last_page;
      this.filtered_images = success.data.images.total;
      this.from = success.data.images.from;
      this.to = success.data.images.to;

    },(error)=>{
      this.userService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

}
