<footer class="pt-4 bg-black">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 font-bold font14">
              <span class="text-white mr-1" ><a routerLink="/" class="text-white">Home</a></span>
              <span class="text-white mx-1">|</span>
              <span class="text-white mr-1" ><a routerLink="/contributors" class="text-white">Contributors</a></span>
              <span class="text-white mx-1">|</span>
              <span class="text-white mr-1" ><a routerLink="/faqs" class="text-white">FAQs</a></span>
              <span class="text-white mx-1">|</span>
              <span class="text-white mx-1" ><a routerLink="/terms" class="text-white">Terms</a></span>
                <span class="text-white mx-1">|</span>
              <span class="text-white mx-1" ><a routerLink="/report-content" class="text-white">Report</a></span>
               <p class="text-grey mt-3">&copy;{{currentYear}} Orbzone Global</p>
            </div>
            <div class="col-lg-6 col-sm-12 text-right sm-text-left text-black">
                <span class="mr-1 social-icon">
                  <a class="pointer" href="http://www.twitter.com/orbzoneglobal" target="_blank">
                    <i class="fab fa-twitter"></i>
                  </a>
                </span>
                <span class="mx-1 social-icon">
                  <a class="pointer" href="https://facebook.com/orbzone" target="_blank">
                    <i class="fab fa-facebook"></i>
                  </a>
                </span>

                <span class="mx-1 social-icon">
                  <a class="pointer" href="https://instagram.com/orbzone" target="_blank">
                    <i class="fab fa-instagram"></i>
                  </a>

                </span>
<!--                <span class="mr-2 text-grey">|</span>-->
                <span class="text-white">
                    <div id="google_translate_element"></div>
<!--                    <i class="fa fa-globe"></i>  English-->
                </span>
            </div>
        </div>
    </div>
</footer>
