import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {User} from '../../models/User';
import {SlugifyPipe} from '../../pipes/SlugifyPipe';

@Component({
  selector: 'app-image-details',
  templateUrl: './image-details.component.html',
  styleUrls: ['./image-details.component.css']
})
export class imageDetailsComponent implements OnInit {

  loading:boolean;
  imid;
  image;
  user:User;
  similarImages = [];
  tag;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public toastr: ToastrService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
    public slugifyPipe : SlugifyPipe


  ) { }

  ngOnInit() {


    this.imid = this.route.snapshot.paramMap.get("imid");
    this.imageDetails();
    this.getSimilarImages();
  // this.route.paramMap.pipe(
  //     switchMap((params: ParamMap) =>
  //       this.imid =  params.get('imid')
  //   ));

  }


  imageDetails(){
    let data = {
      "imid" : this.imid
    };
    this.spinner.show()

    if(this.userService.isLoggedIn){
      this.userService.imageDetails(data).subscribe((success:any)=>{
        this.loading = false;
        this.spinner.hide();

        this.image = success.data.image;
        this.user = success.data.user;
        window.scrollTo(0, 0)

      },(error)=>{
        this.loading = false;
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });

    } else {
      this.userService.publicImageDetails(data).subscribe((success:any)=>{
        this.loading = false;
        this.spinner.hide();

        this.image = success.data.image;
        this.user = success.data.user;
        window.scrollTo(0, 0)

      },(error)=>{
        this.loading = false;
        this.spinner.hide();
        this.toastr.error(error.error.message);
      });

    }

  }

  change(imid){
    this.imid = imid;
    this.image = null;
    this.loading = true;
    this.imageDetails();
  }

  searchForTag(tag){
    this.router.navigate(['search'],{queryParams:{search: tag}});
  }

  viewImage(){
    let data = {
      "imid" : this.image.imid
    };

    this.userService.viewImage(data).subscribe((success:any)=>{
      this.loading = false;

      // view is registered on the server

    },(error)=>{
      this.loading = false;

      this.toastr.error(error.error.message);
    });

  }

  getSimilarImages(){
    let data = {
      "tag" : this.tag
    };

    this.userService.similarImages(data).subscribe((success:any)=>{
      this.loading = false;

      this.similarImages = success.data.images.data;

    },(error)=>{
      this.loading = false;

      this.toastr.error(error.error.message);
    });

  }


  likeImage(image){
    let data = {
      "imid" : image.imid
    };

    if(image.i_like == false){

      this.userService.likeImage(data).subscribe((success:any)=>{
        this.loading = false;

        this.toastr.success(success.message);
        this.imageDetails();

      },(error)=>{
        this.loading = false;

        this.toastr.error(error.error.message);
      });
    } else {

      this.userService.unlikeImage(data).subscribe((success:any)=>{
        this.loading = false;
        this.toastr.success(success.message);

        this.imageDetails();

      },(error)=>{
        this.loading = false;

        this.toastr.error(error.error.message);
      });
    }

  }


}
