<div class="login">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" ></mat-progress-bar>
  <nav class="bg-black py-3">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="logo pointer" routerLink='/'>
          <img src="assets/img/logo-white.png" alt="" class="w-100" />
        </div>
        <div>
          <a routerLink="/sign-up" class="font14 text-white"
            >Create a Free Account</a
          >
        </div>
      </div>
    </div>
  </nav>
  <section class="p-r">
    <div class="pattern3">
      <img src="assets/img/Ellipse1.png" alt="" />
    </div>
    <div class="pattern4">
      <img src="assets/img/line.png" alt="" />
    </div>
    <div class="container">
      <div class="row" style="padding-top: 14vh;">
        <div class="col-lg-6 col-sm-12 my-auto sm-d-none">
          <h3 class="text-black font-bold font64">
            Access unique images from storytellers
          </h3>
          <p class="font21">
            Take advantage of our exclusive platform that allows you access
            afrocentric images and for use in your creative projects.
          </p>
        </div>
        <div class="col-lg-1 col-sm-12"></div>
        <div class="col-lg-5 col-sm-12" style="z-index: 3;">
          <div class="form-card p-r">
            <div class="pattern1">
              <img src="assets/img/pattern1.png" alt="" />
            </div>
            <h4 class="font30 mb-4">Sign In</h4>
            <form *ngIf="forgotPasswordSequence == 'none'"   >
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Phone</span>
<!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                type="tel"
                maxlength="11"
                placeholder="08081234567"
                class="form-control mt-1 mb-4"
                name="phone"
                [(ngModel)]="user.phone"
              />
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Password</span>
                <span><a (click)="passwordResetSequence('first')" class="click blue-text"  > Forgot</a></span>
              </div>
              <input
                type="password"
                placeholder="******* "
                class="form-control mt-1 mb-4"
                name="password"
                [(ngModel)]="user.password"
              />
              <button type="submit" class="btn form-control" (click)="signIn()">
                Sign In
              </button>

              <div class="mt-3 font13">
                <span class="font14 text-bold">New here?</span>
                <a routerLink="/sign-up">Create an Account</a>
              </div>

            </form>


<!--            first step -->
            <form *ngIf="forgotPasswordSequence == 'first'"   >
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Phone</span>
                <!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                type="tel"
                maxlength="11"
                placeholder="08081234567"
                class="form-control mt-1 mb-4"
                name="phone"
                [(ngModel)]="user.phone"
              />
              <button type="submit" class="btn form-control" (click)="startForgotPassword()">
                Reset
              </button>

              <div class="mt-3 font13">
                <a class="blue-text click" (click)="passwordResetSequence('none')">Cancel</a>
              </div>

            </form>

<!--            second step  -->
            <form *ngIf="forgotPasswordSequence == 'second'"   >
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Code</span>
                <!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                type="tel"
                maxlength="6"
                placeholder=""
                class="form-control mt-1 mb-4"
                name="code"
                [(ngModel)]="code"
              />
              <button type="submit" class="btn form-control" (click)="confirmPhoneCode()">
                Submit
              </button>

              <div class="mt-3 font13">
                <a class="blue-text click" (click)="passwordResetSequence('none')">Cancel</a>
              </div>


            </form>

<!--            third step -->
            <form *ngIf="forgotPasswordSequence == 'third'"   >
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>New Password</span>
                <!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                type="password"
                placeholder=""
                class="form-control mt-1 mb-4"
                name="new_password"
                [(ngModel)]="newPassword"
              />
              <div
                class="d-flex align-items-center justify-content-between font14 font-bold"
              >
                <span>Confirm New Password</span>
                <!--                <span><a href="#"> Remind Me</a></span>-->
              </div>
              <input
                type="password"
                placeholder=""
                class="form-control mt-1 mb-4"
                name="new_password"
                [(ngModel)]="confirmNewPassword"
              />

              <button type="submit" class="btn form-control" (click)="finishForgotPassword()">
                Reset
              </button>

              <div class="mt-3 font13">
                <a class="blue-text click" (click)="passwordResetSequence('none')">Cancel</a>
              </div>


            </form>


            <div class="pattern2">
              <img src="assets/img/Ellipse2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
