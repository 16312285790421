<app-navbar></app-navbar>



<section class="py-5 bg-grey">

  <section class="my-5 container">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <h3 class="font58 font-bold mb-5">
          F.A.Qs
        </h3>
        <div class="accordion" id="accordionExample">
          <div id="headingOne">
            <div class="my-4">
              <p
                class="text-brown font20 font-bold click"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is Orbzone?
              </p>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="font18 text-grey">
                Orbzone is a service that provides unlimited downloads of hundreds of thousands of image assets
              </div>
            </div>
          </div>
          <div id="headingTwo">
            <div class="my-4">
              <p
                class="text-brown font20 font-bold click"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Can I cancel and upgrade anytime ?
              </p>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="font18 text-grey">
                Yes, you can decide to cancel and upgrade anytime
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="row">
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="bg-white p-4">
              <img src="assets/img/phone.png" />
              <h4 class="font21 font-bold mt-3 mb-3 text-blue">Call Us</h4>
              <p class="text-grey font13">
                Place a call to our contact centre for quick answers to all your
                questions.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <div class="bg-white p-4">
              <img src="assets/img/email.png" />
              <h4 class="font21 font-bold mt-3 mb-3 text-blue">Send an Email</h4>
              <p class="text-grey font13">
                If you have further enquiries send us an email to info
                orbzone.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
