<section>
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <div class="pt-5 pb-2" style="background: #E5E5E5;">
    <h3 class="font28 font-bold container">My Account</h3>
  </div>
  <section class="py-5 bg-grey">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <div
            class="bg-black pb-3 pt-4 text-center"
            style="border-radius:6px 6px 0px 0px; "
          >
            <div class="profile-img mx-auto">
              <img *ngIf="userService.user.image" [src]="userService.user.image" alt="" />
              <img *ngIf="!userService.user.image" src="assets/img/avatar.png" alt="" />
            </div>
            <p class="mt-2 font24 font-bold text-white ">{{userService.user.firstname}}<br />{{userService.user.surname}}</p>
          </div>
          <div
            class="px-5 py-3 d-flex justify-content-between font-bold font12 text-white"
            style="background: #3e3e3e; border-radius: 0px 0px 6px 6px;"
          >
            <span data-toggle="modal" class="pointer" data-target="#editProfileModal">EDIT PROFILE</span>
            <span (click)="userService.signOut()">SIGN OUT</span>
          </div>
          <button data-toggle="modal" data-target="#updatePasswordModal" class="bg-red btn text-white w-100 my-4">
            Change Password
          </button>
<!--          <div class="bg-white border py-5 px-4">-->
<!--            <h3 class="font18 font-bold mb-2">Monthly</h3>-->
<!--            <div class="my-3 font14">-->
<!--              <p class="text-grey mb-0">Subscription renewal date</p>-->
<!--              <p>March 5, 2020 (in 15 days)</p>-->
<!--            </div>-->
<!--            <div class="my-3 font14">-->
<!--              <p class="text-grey mb-0">What you'll be charged</p>-->
<!--              <p>NGN  1500 (including tax)</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <p class="mt-3 font12 text-underline">Cancel subscription</p>-->
        </div>
        <div class="col-lg-9 col-sm-12">
<!--            <div class="bg-white border p-4">-->
<!--                <h3 class="font20 font-bold">Switch to annual payments and save 50% <span class="text-green"> NGN38,000/year N1500/month)</span></h3>-->
<!--                <p class="font14 font-light mt-3">-->
<!--                    We will adjust the amount we charge you according to the remaining days on your current subscription.-->
<!--                </p>-->
<!--                <button class="btn bg-green text-white font-bold mt-2 px-5">Switch Your Plan</button>-->
<!--            </div>-->
            <h3 class="font24 font-bold my-5">Payment Methods</h3>
            <div class="bg-white border p-4">
                <div class="row">
                  <div class="col-12">
                    <button
                      (click)="startCardAddition()"
                      class="btn text-green float-right border-green font14">Add Card</button>
                  </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <p class="font18 font-bold">Debit Card</p>

                      <div class="table-responsive ">
                        <table class="table table-borderless no-border">
                          <tbody>
                          <tr class="no-border" *ngFor="let card of cards">
                            <td>
                              <img height="30" *ngIf="card.type == 'VISA'" src="assets/img/visa.png" alt=""/>
                              <img height="30" *ngIf="card.type == 'MASTERCARD'" src="assets/img/mastercard.png" alt=""/>
                            </td>
                            <td class="font-bold">
                              **** **** *** {{card.last_four}}
                            </td>
                            <td>
                              {{card.expiry}}
                            </td>
                            <td>{{card.bank}}</td>
                            <td>
                            <span
                              (click)="selectCard(card.cardid)"
                              class="mx-2"
                              data-toggle="modal"
                              data-target="#deleteCardModal"
                            >
                              <img class="delete-icon" src="assets/img/delete.png" alt=""/>
                            </span>

                            </td>

                          </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                </div>
            </div>


            <h3 class="font24 font-bold mt-5 mb-4 pt-4">Bank Accounts</h3>

          <button
            data-toggle="modal"
            data-target="#addBankAccountModal"
            class="btn text-green float-right border-green font14">Add Bank Account</button>

          <div class="table-responsive ">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Number</th>
                        <th scope="col">Bank</th>
                        <th scope="col">Date Added</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let account of accounts">
                        <td>{{account.account_name}}</td>
                        <td>{{account.account_number}}</td>
                        <td>{{account.account_bank}}</td>
                        <td>{{account.created_at}}</td>
                        <td>

                          <span
                            (click)="selectAccount(account.accountid)"
                            class="mx-2"
                            data-toggle="modal"
                            data-target="#deleteAccountModal"
                          >
                              <img class="delete-icon" src="assets/img/delete.png" alt=""/>
                            </span>

                        </td>


                      </tr>
                    </tbody>
                  </table>

            </div>
        </div>
      </div>
    </div>
  </section>


  <!-- delete card modal -->
  <div
    class="modal fade"
    id="deleteCardModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteCardModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header px-4">
          <h5 class="modal-title font20" id="deleteCardModalLabel">Confirm Delete</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-5">
          <p class="font-light text-center">
            Are you sure you want to delete this card? Click the ‘No’ button to
            cancel this action
          </p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn bg-white text-red border-red" data-dismiss="modal">
            No
          </button>
          <button class="btn bg-red text-white" data-dismiss="modal" (click)="deleteCard()">Yes</button>
        </div>
      </div>
    </div>
  </div>

  <!-- delete account modal -->
  <div
    class="modal fade"
    id="deleteAccountModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteAccountModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header px-4">
          <h5 class="modal-title font20" id="deleteAccountModalLabel">Confirm Delete</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body px-5">
          <p class="font-light text-center">
            Are you sure you want to delete this Account? Click the ‘No’ button to
            cancel this action
          </p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn bg-white text-red border-red" data-dismiss="modal">
            No
          </button>
          <button class="btn bg-red text-white" data-dismiss="modal" (click)="deleteAccount()">Yes</button>
        </div>
      </div>
    </div>
  </div>



  <!-- add new card modal-->
  <div class="modal fade" id="addBankAccountModal" tabindex="-1" role="dialog" aria-labelledby="addBankAccountModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header px-5">
          <h5 class="modal-title" id="addBankAccountModalLabel">Add Bank Account</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="my-3">

              <div class="form-group">
                <label>Choose Bank</label>
                <select [(ngModel)]="bank"  name="bank"
                        class="form-control"
                >
                  <option value="" disabled>Select a Bank</option>
                  <option>Access Bank</option>
                  <option>ALAT by WEMA</option>
                  <option>ASO Savings and Loans</option>
                  <option>Citi Bank</option>
                  <option>EcoBank PLC</option>
                  <option>Ekondo Microfinance Bank</option>
                  <option>Enterprise Bank</option>
                  <option>Fidelity Bank</option>
                  <option>First Bank PLC</option>
                  <option>First City Monument Bank</option>
                  <option>FINATRUST MICROFINANCE BANK</option>
                  <option>FSDH Merchant Bank</option>
                  <option>Globus Bank</option>
                  <option>Guaranty Trust Bank</option>
                  <option>Heritage Bank</option>
                  <option>Highstreet Microfinance Bank</option>
                  <option>Jaiz Bank</option>
                  <option>Keystone Bank</option>
                  <option>Kuda</option>
                  <option>MainStreet Bank</option>
                  <option>Parallex Bank</option>
                  <option>Polaris Bank</option>
                  <option>ProvidusBank PLC</option>
                  <option>Rand merchant Bank</option>
                  <option>Stanbic IBTC Bank</option>
                  <option>Standard Chartered bank PLC</option>
                  <option>Sterling Bank PLC</option>
                  <option>Suntrust Bank</option>
                  <option>TCF MFB</option>
                  <option>Union Bank PLC</option>
                  <option>United Bank for Africa</option>
                  <option>Unity Bank PLC</option>
                  <option>Wema Bank PLC</option>
                  <option>Zenith bank PLC</option>
                </select>

              </div>
<!--              <input-->
<!--                [(ngModel)]="bank"-->
<!--                name="bank"-->
<!--                type="text"-->
<!--                class="form-control common-input w-100 bg-white"-->
<!--                placeholder="Bank Name"-->
<!--              />-->
            </div>
            <div class="my-3">
              <input
                [(ngModel)]="account_number"
                name="account_number"
                type="text"
                class="form-control common-input w-100 bg-white"
                placeholder="Account Number"
                (blur)="resolveAccount()"
              />
            </div>
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="my-3">
                  <input
                    [disabled]="loadingAccountName"
                    [(ngModel)]="account_name"
                    name="account_name"
                    type="text"
                    class="form-control common-input w-100 bg-white"
                    placeholder="Account Name"
                  />
                </div>
              </div>
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-between">
            <button class="btn border-green bg-white text-green border-radius" data-dismiss="modal">Cancel</button>
            <button data-dismiss="modal" class="btn bg-green text-white border-radius" (click)="addBankAccount()">Save


              <mat-spinner
                style="color:white !important;float: right"
                *ngIf="loading"
                [diameter]="20"
                color="#FFFF"
              ></mat-spinner>

            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- update password modal-->
  <div class="modal fade" id="updatePasswordModal" tabindex="-1" role="dialog" aria-labelledby="updatePasswordModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header px-5">
          <h5 class="modal-title" id="updatePasswordModalLabel">Update Password</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="my-3">
              <input
                type="password"
                class="form-control common-input w-100 bg-white"
                placeholder="Current Password"
              />
            </div>
            <div class="my-3">
              <input
                type="password"
                class="form-control common-input w-100 bg-white"
                placeholder="New Password"
              />
            </div>
            <div class="my-3">
              <input
                type="password"
                class="form-control common-input w-100 bg-white"
                placeholder="Confirm New Password"
              />
            </div>
          </form>
          <div class="modal-footer d-flex justify-content-between">
            <button class="btn border-green bg-white text-green border-radius">Cancel</button>
            <button class="btn bg-green text-white border-radius">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- edit profile modal-->
  <div class="modal fade" id="editProfileModal" tabindex="-1" role="dialog" aria-labelledby="editProfileModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header px-5">
          <h5 class="modal-title" id="editProfileModalLabel">Edit Profile</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 d-flex justify-content-center">
              <div class="p-r profile-img-box p-3  m-auto">
                <div class="profile-img col-12">

                <img *ngIf="userService.user.image" [src]="userService.user.image" alt="" class="width-100">
                <img *ngIf="!userService.user.image" [src]="profileImage" alt="" class="width-100" />
                <span (click)="choosePhoto(profile_image_button)" class="add-icon"><img src="assets/img/add-icon.svg" alt=""/></span>
                <input #profile_image_button (change)="selectProfileImageFile($event)" type="file" class="d-none">
                </div>

              </div>
            </div>
            <div class="col-lg-12 col-sm-12 sm-mt">
              <form>
                <div class="row">
                  <div class="my-3 col-lg-6 col-sm-12">
                    <input
                      type="text"
                      name="firstname"
                      [(ngModel)]="userService.user.firstname"
                      class="form-control common-input w-100 bg-white"
                      placeholder="Firstname"
                    />
                  </div>
                  <div class="my-3 col-lg-6 col-sm-12">
                    <input
                      type="text"
                      name="surname"
                      [(ngModel)]="userService.user.surname"
                      class="form-control common-input w-100 bg-white"
                      placeholder="Surname"
                    />
                  </div>

                </div>
                <div class="row">
                  <div class="my-3 col-12">
                    <input
                      disabled
                      name="email"
                      [(ngModel)]="userService.user.email"
                      type="email"
                      class="form-control common-input w-100 bg-white"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-3 col-12">
                    <input
                      type="text"
                      name="gender"
                      [(ngModel)]="userService.user.gender"
                      class="form-control common-input w-100 bg-white"
                      placeholder="Gender"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="my-3 col-12">

                    <small>Current DOB: {{this.userService.user.dob}}</small>
                    <input type="text"
                           name="dob"
                           placeholder="Change Dob"
                           class="form-control"
                           [(bsValue)]="dob"
                           (bsValueChange)="dateChanged()"
                           [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                           bsDatepicker>

                  </div>
                </div>
                <div class="row">
                  <div class="my-3 col-12">
                    <input
                      type="text"
                      name="brand_name"
                      [(ngModel)]="userService.user.brand_name"
                      class="form-control common-input w-100 bg-white"
                      placeholder="Brand Name"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="my-3 col-12">
                    <textarea
                      name="address"
                      [(ngModel)]="userService.user.address"
                      class="form-control" placeholder="Address"></textarea>
                  </div>
                </div>

              </form>
            </div>
          </div>
          <button class="btn bg-green text-white border-radius float-right" data-dismiss="modal" (click)="editProfile()">Update</button>
        </div>
      </div>
    </div>
  </div>

  <!-- end modal -->

</section>
