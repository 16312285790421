<div class="login">
  <nav class="bg-black py-3">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="logo">
          <img src="assets/img/logo-white.png" alt="" class="w-100" />
        </div>
        <div>
          <a routerLink="/signup" class="font14 text-white"
            >Create a Free Account</a
          >
        </div>
      </div>
    </div>
  </nav>
  <section class="p-r">
    <div class="pattern3">
      <img src="assets/img/Ellipse1.png" alt="" />
    </div>
    <div class="pattern4">
      <img src="assets/img/line.png" alt="" />
    </div>
    <div class="container">
      <div class="row" style="padding-top: 14vh;">
        <div class="col-lg-6 col-sm-12 my-auto sm-d-none">
          <h3 class="text-black font-bold font64">We are happy to have you</h3>
          <p class="font21">
            Use your Orbzone acount to browse through afrocentric images and use
            them in your creative projects.
          </p>
        </div>
        <div class="col-lg-1 col-sm-12"></div>
        <div class="col-lg-5 col-sm-12" style="z-index: 3;">
          <div class="form-card p-r">
            <div class="pattern1">
              <img src="assets/img/pattern1.png" alt="" />
            </div>
            <img src="assets/img/logo.png" alt="" class="mb-4"/>
            <h4 class="font30 font-bold mb-4">Verify your email</h4>
            <p class="font14">Hi sarah! Use the link below to verify your email and start enjoying Orbzone.</p>

              <button type="submit" class="btn form-control bg-red text-white" routerLink='/client-dashboard'>
                Verify
              </button>
            <div class="mt-3 font13">
              <span class="font14 text-bold">Questions? Email us at <a href='mailto:info@orbzone.ng'>info@orbzone.ng</a></span>
            </div>
            <div class="pattern2">
              <img src="assets/img/Ellipse2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
