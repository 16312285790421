<div class="login">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <mat-progress-bar *ngIf="loading" mode="indeterminate" ></mat-progress-bar>
  <nav class="bg-black py-3">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="logo pointer">
          <img src="assets/img/logo-white.png" alt="" class="w-100"/>
        </div>
        <div>
          <a routerLink="/signup" class="font14 text-white"
            >Create a Free Account</a
          >
        </div>
      </div>
    </div>
  </nav>
  <section class="p-r">
    <div class="pattern3">
      <img src="assets/img/Ellipse1.png" alt=""/>
    </div>
    <div class="pattern4">
      <img src="assets/img/line.png" alt=""/>
    </div>
    <div class="container">
      <div class="row" style="padding-top: 4vh;">
        <div class="col-lg-6 col-sm-12 my-auto sm-d-none">
          <h3 class="text-black font-bold font64">Access unique
              images from
              storytellers
              </h3>
              <p class="font21">
                  Take advantage of our exclusive platform that allows you access afrocentric images and for use in your creative projects.
              </p>
              <p class="my-5 font12 font-light">
                By creating an account you agree to our <span class='text-light-blue pointer' routerLink="/terms" >Terms and Conditions</span>
              </p>
        </div>
        <div class="col-lg-1 col-sm-12"></div>
          <div class="col-lg-5 col-sm-12">
            <div class="form-card p-r p-4">
              <div class="d-flex justify-content-between align-items-center">
                  <h4 class="font30 mb-4">Sign Up</h4>
                  <div>
                      <img src="assets/img/slider.png" alt=""/>
                  </div>
              </div>
                <h6 class="font30 mb-4" *ngIf="user">Welcome {{user.firstname}}</h6>
            <form>
              <div class="d-flex align-items-center justify-content-between font14 font-bold">
                  <span >Firstname</span>
              </div>
              <input type="text" name="firstname" [(ngModel)]="user.firstname" class="form-control mt-1 mb-4"/>
              <div class="d-flex align-items-center justify-content-between font14 font-bold">
                  <span >Last name</span>
              </div>
              <input type="text" name="surname" [(ngModel)]="user.surname" class="form-control mt-1 mb-4"/>

              <div class="d-flex align-items-center justify-content-between font14 font-bold">
                <span >Phone</span>
              </div>
              <input type="tel" name="phone" [(ngModel)]="user.phone" class="form-control mt-1 mb-4"/>

              <div class="d-flex align-items-center justify-content-between font14 font-bold">
                  <span >Email</span>
              </div>

              <input type="email" name="email" [(ngModel)]="user.email" class="form-control mt-1 mb-4"/>
              <div class="d-flex align-items-center justify-content-between font14 font-bold">
                  <span >Password</span>
              </div>
              <input type="password" name="password" [(ngModel)]="user.password" class="form-control mt-1 mb-4"/>
              <button (click)="signUp()" class="btn form-control">Sign Up</button>
            </form>
            <div class="mt-3 font13">
                <span class='font14 text-bold'>Already an Orbzone Member?</span> <a href="#" routerLink="/sign-in">Sign In </a>
            </div>
            <div class="pattern2">
              <img src="assets/img/Ellipse2.png" alt=""/>
            </div>
            </div>
        </div>
      </div>
    </div>
  </section>
  </div>
