<app-navbar></app-navbar>
<section class="bg-grey">
  <section class="client-home">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <h3 class="font64 font-bold text-white">
            Share your work and start earning
          </h3>
          <p class="font-bold font20 my-4 text-white">
            Join Orbzone's community of contributors doing what you love and earn
            royalties as you contribute
          </p>
          <button class="btn bg-white py-2 px-4" routerLink='/sign-up'>Get Started</button>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5 container">
    <div class="row mt-4">
      <div class="col-lg-4 col-sm-12">
        <h3 class="font58 font-bold">
          Become a Contributor
        </h3>
      </div>
      <div class="col-lg-4 col-sm-12"></div>
      <div class="col-lg-4 col-sm-12 text-right sm-text-left sm-mt my-auto">
        <button class="btn bg-white py-2 px-4 border-radius" routerLink='/sign-up'>
          Get Started
        </button>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-lg-3 col-md-4 col-sm-12 sm-mt">
        <div class="contribute-card bg-white border-radius p-4">
          <div>
            <h3 class="font21 font-bold">Create</h3>
            <p class="my-3 text-grey">
              Produce high-quality images and videos for our customers to
              download
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 sm-mt">
        <div class="contribute-card bg-white border-radius p-4">
          <div>
            <h3 class="font21 font-bold">Submit</h3>
            <p class="my-3 text-grey">
              Upload your content with our easy to use platform and start
              earning
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 sm-mt">
        <div class="contribute-card bg-white border-radius p-4">
          <div>
            <h3 class="font21 font-bold">Get paid</h3>
            <p class="my-3 text-grey">
              Make money everytime your content is downloaded and used by our
              customers
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12 sm-mt">
        <div class="contribute-card bg-white border-radius p-4">
          <div>
            <h3 class="font21 font-bold">Refer</h3>
            <p class="my-3 text-grey">
              Earn even more by referring new contributors and customers
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="client-home client-footer">
    <div class="container text-white">
      <h3 class="font48 font-bold">Start earning today</h3>
      <p class="my-4 font20">
        Contribute to Orbzone to make money and start doing what you love.
      </p>
      <button class="btn bg-white py-2 px-4 font-bold" routerLink='/sign-up'> Join Now</button>
      <p class="font20 mt-4 pointer">Already have a account?<span routerLink='/sign-in'>Sign in</span></p>
    </div>
  </section>
</section>
