import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {

  @Input('page')
  page:  number;

  @Input('pages')
  pages: number;

  constructor(
    public router : Router,
    public route : ActivatedRoute
  ) { }

  ngOnInit(): void {
    // this.route.queryParams.subscribe(queryParams => {
    //   this.page = queryParams["page"];
    // });

  }

  goToPage(page){
    this.page = page;
    const queryParams: Params = { page: this.page };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
        // replaceUrl : true
      });

  }

}
