<app-navbar></app-navbar>

<section class="client-home login">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-12 my-auto">
        <h3 class="font64 font-bold text-white">
          Join the community and sell in seconds
        </h3>
        <p class="font20 my-4 text-white">
          Take advantage of our exclusive platform that allows you share your
          afrocentric images and earn profit.
        </p>
        <p class="font13 text-white font-light">
          By creating an account you agree to our Terms and Conditions
        </p>
      </div>
      <div class="col-lg-1 col-sm-12"></div>
      <div class="col-lg-5 col-sm-12">
        <div class="form-card p-r p-4">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="font30 mb-4">Sign Up</h4>
            <div>
              <img src="assets/img/slider.png" alt="" />
            </div>
          </div>
          <form>
            <div
              class="d-flex align-items-center justify-content-between font14 font-bold"
            >
              <span>Firstname</span>
            </div>
            <input type="text" class="form-control mt-1 mb-4" />
            <div
              class="d-flex align-items-center justify-content-between font14 font-bold"
            >
              <span>Last name</span>
            </div>
            <input type="text" class="form-control mt-1 mb-4" />
            <div
              class="d-flex align-items-center justify-content-between font14 font-bold"
            >
              <span>Email</span>
            </div>
            <input type="text" class="form-control mt-1 mb-4" />
            <div
              class="d-flex align-items-center justify-content-between font14 font-bold"
            >
              <span>Password</span>
            </div>
            <input type="password" class="form-control mt-1 mb-4" />
            <button
              type="submit"
              class="btn form-control"
              routerLink="/verify-email"
            >
              Next
            </button>
          </form>
          <div class="mt-3 font13">
            <span class="font14 text-bold">Already an Orbzone Member?</span>
            <a href="#">Sign In </a>
          </div>
          <div class="pattern2">
            <img src="assets/img/Ellipse2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
