import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {User} from '../../models/User';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public user:User = new User();
  public loading:boolean = false;
  public returnUrl;
  public forgotPasswordSequence = "none"; // none
  public code;
  public newPassword;
  public confirmNewPassword;

  constructor(
    private route:ActivatedRoute,
    public userService:UserService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService

  ) {
    this.user.phone = "";
    this.user.password = "";
  }

  ngOnInit() {
    /** spinner starts on init */
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);

    this.returnUrl = this.userService.nextUrl != '' ?  this.userService.nextUrl : '/user/dashboard';

  }

  signIn(){
    let data = this.user;
    this.loading = true;
    this.userService.signIn(data).subscribe((success:any)=>{
      this.loading = false;

      this.toastr.success(success.message);
      this.userService.authToken = success.data.token;
      this.userService.setUser(success.data.user);
      this.router.navigateByUrl(this.returnUrl);
      this.userService.nextUrl = null;

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }

  passwordResetSequence(stage){
    this.forgotPasswordSequence = stage;
  }

  startForgotPassword(){
    let data = this.user;
    this.loading = true;
    this.userService.startForgotPassword(data).subscribe((success:any)=>{
      this.loading = false;

      this.toastr.success(success.message);
      this.passwordResetSequence('second')

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }


  confirmPhoneCode(){
    let data = {
      'phone' : this.user.phone,
      'code' : this.code
    };
    this.loading = true;
    this.userService.confirmPhoneCode(data).subscribe((success:any)=>{
      this.loading = false;

      if(success.message == 'Valid'){
        this.passwordResetSequence('third');
      }

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }



  finishForgotPassword(){
    let data = {
      'phone' : this.user.phone,
      'code' : this.code,
      'new_password' : this.newPassword,
      'new_password_confirmation' : this.confirmNewPassword
    };
    this.loading = true;
    this.userService.finishForgotPassword(data).subscribe((success:any)=>{
      this.loading = false;

      if(success.message == 'Password Changed.'){
        this.passwordResetSequence('none');
        this.toastr.success(success.message);
      }

    },(error)=>{
      this.loading = false;
      this.toastr.error(error.error.message);
    });
  }




}
