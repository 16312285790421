import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClientDashboardComponent} from './client-dashboard/client-dashboard.component';
import {AuthGuard} from '../auth/auth.guard';
import {UserComponent} from './user/user.component';
import {ProfileComponent} from '../pages/profile/profile.component';
import {ClientSubmitContentComponent} from './client-submit-content/client-submit-content.component';
import {ClientUploadContentComponent} from './client-upload-content/client-upload-content.component';


const routes: Routes = [
  {path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    children : [
      // {
      //   path: "",
      //   children: [
          {path: "dashboard", component : ClientDashboardComponent},
          {path: 'profile', component: ProfileComponent},
          {path: 'image/:imid', component: ClientSubmitContentComponent},
          {path: 'upload', component: ClientUploadContentComponent},
          {path: "", redirectTo : "dashboard",pathMatch: "full"},
        // ]
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
