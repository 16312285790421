import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from './services/user/user.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PurchaseGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(this.userService.isLoggedIn){
      return true;
    } else {
      this.toastr.info("Please login to complete purchase");
      this.userService.nextUrl = state.url;
      return this.router.navigate(['/sign-in'],{queryParams: {"returnUrl" : state.url}});
    }

  }

}
