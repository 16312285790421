import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user/user.service';


@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit {

  loading:boolean;
  imid;
  image;
  cards;
  accounts;
  cardid;
  agreed;


  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public userService: UserService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.imid = this.route.snapshot.paramMap.get("imid");
    this.imageDetails();
    this.getUpdatedProfile();
  }

  getUpdatedProfile(){
    let data = {

    };
    this.spinner.show();
    this.userService.profile(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.cards = success.data.cards;
      this.accounts = success.data.accounts;
      this.userService.user = success.data.user;

    },(error)=>{
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }


  imageDetails(){
    let data = {
      "imid" : this.imid
    };
    this.spinner.show();

    this.userService.publicImageDetails(data).subscribe((success:any)=>{
      this.loading = false;
      this.spinner.hide();

      this.image = success.data.image;
      window.scrollTo(0, 0)

    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  onSelect(event) {
    if ( event.target.checked ) {
      this.agreed = true;
    } else{
      this.agreed = false;
    }
  }

  selectCard(cardid){
    this.cardid = cardid;
  }

  buyImage(){

    if(!this.agreed) {
      this.toastr.error("You have to accept the terms");
      return;
    }

    if(this.cardid == null){
      this.toastr.error("Please select a card");
      return;
    }


    if(this.agreed){

      let data = {
        "imid" : this.imid,
        "cardid" : this.cardid
      };
      this.loading = true;

      this.userService.buyImage(data).subscribe((success:any)=>{
        this.loading = false;

        this.toastr.success(success.message);
        this.router.navigate(['/user/dashboard']);
      },(error)=>{
        this.loading = false;
        this.toastr.error(error.error.message);
      });


    }

  }

}
