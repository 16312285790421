export class User{
  public uid;
  public email;
  public phone;
  public firstname;
  public surname;
  public dob;
  public gender;
  public brand_name;
  public address;
  public password;
  public image;
  public balance;
  public contribution_count;
  public refcode;
  public last_login;
  public cards: any[];
  public accounts: any[];
  public withdrawals: any[];

}

