
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
            <a class="navbar-brand mr-5 pointer" href="/">
                <img src="assets/img/logo-white.png"/>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span>
                <i class="fa fa-bars btn text-white font20"></i>
              </span>
            </button>
            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
              <div class="d-flex align-items-center">
<!--                <select>-->
<!--                  <option value="categories">Categories</option>-->
<!--                  <option value="categories">Categories</option>-->
<!--                  <option value="categories">Categories</option>-->
<!--                </select>-->
                <input type="text" placeholder="Search for photo" name="search" (keyup.enter)="searchForItem()" [(ngModel)]="search" />
                <i class="fa fa-search text-white input-icon"></i>
              </div>
              <ul class="navbar-nav text-capitalize">
                <li class="nav-item">
                  <a class="nav-link text-bold mr-2" routerLink='/search'>Browse</a>
                </li>
                <li class="nav-item" >
                  <a class="nav-link text-bold mr-5"  *ngIf="!userService.user" routerLink="/sign-in">Sign In</a>
                  <a class="nav-link text-bold mr-3" *ngIf="userService.user"  routerLink="/user/dashboard">Dashboard</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-bold mr-3" routerLink="/user/upload">Upload Images</a>
                </li>
                <li class="nav-item" *ngIf="userService.user">
                  <a class="nav-link text-bold mr-3" href="#" (click)="userService.signOut()">Sign Out</a>
                </li>
                <li class="nav-item ml-3" routerLink='/user/profile' *ngIf="userService.user">
                  <img class="profile-icon" height="35" *ngIf="userService.user.image" [src]="userService.user.image" />
                  <img class="profile-icon" height="35" *ngIf="!userService.user.image" src="assets/img/avatar.png" />
                </li>
              </ul>
            </div>
            </div>
          </nav>
