import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user/user.component';
import {AppModule} from '../app.module';
import {ContributorsComponent} from '../pages/contributors/contributors.component';
import {ClientSignupComponent} from './client-signup/client-signup.component';
import {ClientDashboardComponent} from './client-dashboard/client-dashboard.component';
import {ClientUploadContentComponent} from './client-upload-content/client-upload-content.component';
import {ClientSubmitContentComponent} from './client-submit-content/client-submit-content.component';
import {ProfileComponent} from '../pages/profile/profile.component';
import {NavbarComponent} from '../components/navbar/navbar.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [
    UserComponent,
    NavbarComponent,
    ContributorsComponent,
    ClientSignupComponent,
    ClientDashboardComponent,
    ClientUploadContentComponent,
    ClientSubmitContentComponent,
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    MatProgressBarModule,
    NgxDropzoneModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    NavbarComponent
  ]
})
export class UserModule { }
