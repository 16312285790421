<app-navbar></app-navbar>
<section class="mt-5 pb-5 px-4 hidden detail-header bg-white">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <div class="row" *ngIf="image">
    <div class="col-lg-12 col-md-12 col-sm-12 z-index-0">
      <div class="img-box image-details" align="center">
        <img *ngIf="image" [src]="image.preview" alt=""  />
      </div>
      <p class="font14 font-light mt-4 mb-2">
        Photo ID: {{image.imid}} <br>
        Views: {{image.views}} <br>
        Likes : {{image.likes}} <br>
        <i *ngIf="userService.isLoggedIn" [ngClass]="{'text-danger' : image.i_like == true}" (click)="likeImage(image)" class="click fa fa-heart font48"></i>
      </p>
      <p class="font18 font-bold" *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>

      <p class="font18 font-bold" *ngIf="image.user.brand">By {{image.user.brand}}</p>

    </div>

  </div>
  <div class="row mt-5">
    <div class="col-lg-8 col-md-6 col-sm-12">
        <div>

            <h3 class="font24 font-bold mb-4">Tags</h3>
            <div>
                <span (click)="searchForTag(tag)" class="pointer badge badge-keywords" *ngFor="let tag of image.tags">{{tag}}</span>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <h3 class="font-bold font28">
        {{image.name}}
      </h3>
      <p class="font14 font-bold mt-4" *ngIf="image.category">{{image.category}}</p>
      <p class="font14 font-bold mt-4" *ngIf="!image.category">Not Categorized</p>

      <div>
        <!--        <div class="my-1">-->
        <!--          <span class="font14 font-bold ml-3">Size</span>-->
        <!--        </div>-->
        <!--        <div class="my-1">-->
        <!--          <label class="check-container">-->
        <!--            <input type="checkbox" />-->
        <!--            <span class="checkmark"></span>-->
        <!--          </label>-->
        <!--          <span class="font14 font-bold pl-4 ml-2 text-grey">Original</span>-->
        <!--        </div>-->
        <!--        <div class="my-1">-->
        <!--          <label class="check-container">-->
        <!--            <input type="checkbox" />-->
        <!--            <span class="checkmark"></span>-->
        <!--          </label>-->
        <!--          <span class="font14 font-bold pl-4 ml-2 text-grey">Large</span>-->
        <!--        </div>-->
        <!--        <div class="my-1">-->
        <!--          <label class="check-container">-->
        <!--            <input type="checkbox" />-->
        <!--            <span class="checkmark"></span>-->
        <!--          </label>-->
        <!--          <span class="font14 font-bold pl-4 ml-2 text-grey">Medium</span>-->
        <!--        </div>-->
        <!--        <div class="my-1">-->
        <!--          <label class="check-container">-->
        <!--            <input type="checkbox" />-->
        <!--            <span class="checkmark"></span>-->
        <!--          </label>-->
        <!--          <span class="font14 font-bold pl-4 ml-2 text-grey">Small</span>-->
        <!--        </div>-->
        <a class="btn bg-red font-bold text-white w-100 mb-5 mt-4 download-image-details" [routerLink]="['/purchase',slugifyPipe.transform(image.name),image.imid]">
          Download
        </a>
        <h3 class="font18 font-bold">Attributes</h3>
        <div
          class="d-flex align-items-center justify-content-between font14 mb-4"
        >
          <div *ngFor="let attribute of image.attributes">
            <div >
              <p class="mb-1">{{attribute.name}}</p>
            </div>
            <div>
              <p class="mb-1">{{attribute.value}}</p>

            </div>
          </div>

        </div>
        <h5 class="font14">Commercial License</h5>
      </div>
    </div>
<!--    <div class="col-lg-1 col-md-6 col-sm-12"></div>-->
<!--    <div class="col-lg-3 col-md-6 col-sm-12 sm-mt">-->
<!--        <p class="font14">Share Image</p>-->
<!--        <div class="social-icons">-->
<!--            <i class="fa fa-facebook"></i>-->
<!--            <i class="fa fa-twitter"></i>-->
<!--            <i class="fa fa-google"></i>-->
<!--            <i class="fa fa-facebook"></i>-->
<!--        </div>-->
<!--        <button class="btn text-white bg-green w-100">Share</button>-->
<!--    </div>-->
<!--    <div class="col-lg-1 col-md-6 col-sm-12"></div>-->

  </div>
</section>

<section class="bg-grey px-4 py-4 hidden">
  <div class="d-flex justify-content-between align-items-center">
    <p class="font24 font-bold">Similar Images</p>
    <p class="font14 font-bold text-green" (click)="getSimilarImages()">See More</p>
  </div>
  <div class="row pb-4">
    <div class="col-lg-3 col-md-4 col-sm-12" *ngFor="let image of similarImages">
      <div
        class="bg-white my-4 border-radius box-shadow"
        (click)="change(image.imid)"
      >
        <div class="img-box browse-image">
          <img [src]="image.preview" alt="" class="w-100" />
        </div>
        <div class="p-3 d-flex align-items-center justify-content-between">
          <div>
            <p class="mb-0">{{image.name}}</p>
            <p class="font18 font-bold" *ngIf="!image.user.brand">By {{image.user.firstname}} {{image.user.surname}}</p>
            <p class="font18 font-bold" *ngIf="image.user.brand">By {{image.user.brand}}</p>
          </div>
          <div>
            <img src="assets/img/Group.png" alt="" />
          </div>
        </div>
      </div>
    </div>

  </div>
<!--  <div>-->
<!--    <p class="font24 font-bold">More From Daniel-Dash</p>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-lg-3 col-md-4 col-sm-12">-->
<!--      <div-->
<!--        class="bg-white my-4 border-radius box-shadow"-->
<!--        routerLink="/pictureDetail"-->
<!--      >-->
<!--        <div class="img-box">-->
<!--          <img src="assets/img/Group91.png" alt="" class="w-100" />-->
<!--        </div>-->
<!--        <div class="p-3 d-flex align-items-center justify-content-between">-->
<!--          <div>-->
<!--            <p class="mb-0">Boy wearing blue shirt sitting on swing.</p>-->
<!--            <p>By Daniel Fosh</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img src="assets/img/Group.png" alt="" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-3 col-md-4 col-sm-12">-->
<!--      <div-->
<!--        class="bg-white my-4 border-radius box-shadow"-->
<!--        routerLink="/pictureDetail"-->
<!--      >-->
<!--        <div class="img-box">-->
<!--          <img src="assets/img/Group91.png" alt="" class="w-100" />-->
<!--        </div>-->
<!--        <div class="p-3 d-flex align-items-center justify-content-between">-->
<!--          <div>-->
<!--            <p class="mb-0">Boy wearing blue shirt sitting on swing.</p>-->
<!--            <p>By Daniel Fosh</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img src="assets/img/Group.png" alt="" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-3 col-md-4 col-sm-12">-->
<!--      <div-->
<!--        class="bg-white my-4 border-radius box-shadow"-->
<!--        routerLink="/pictureDetail"-->
<!--      >-->
<!--        <div class="img-box">-->
<!--          <img src="assets/img/Group91.png" alt="" class="w-100" />-->
<!--        </div>-->
<!--        <div class="p-3 d-flex align-items-center justify-content-between">-->
<!--          <div>-->
<!--            <p class="mb-0">Boy wearing blue shirt sitting on swing.</p>-->
<!--            <p>By Daniel Fosh</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img src="assets/img/Group.png" alt="" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-3 col-md-4 col-sm-12">-->
<!--      <div-->
<!--        class="bg-white my-4 border-radius box-shadow"-->
<!--        routerLink="/pictureDetail"-->
<!--      >-->
<!--        <div class="img-box">-->
<!--          <img src="assets/img/Group91.png" alt="" class="w-100" />-->
<!--        </div>-->
<!--        <div class="p-3 d-flex align-items-center justify-content-between">-->
<!--          <div>-->
<!--            <p class="mb-0">Boy wearing blue shirt sitting on swing.</p>-->
<!--            <p>By Daniel Fosh</p>-->
<!--          </div>-->
<!--          <div>-->
<!--            <img src="assets/img/Group.png" alt="" />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</section>
