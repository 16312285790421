import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchComponent } from './pages/search/search.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { TermsConditionComponent } from './pages/terms-condition/terms-condition.component';
import { imageDetailsComponent } from './pages/image-details/image-details.component';
import { SubcriptionComponent } from './pages/subcription/subcription.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormBuilder, FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {UserModule} from './user/user.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FaqsComponent } from './faqs/faqs.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {HttpErrorInterceptor} from './services/interceptors/http-error.interceptor';
import {SlugifyPipe} from './pipes/SlugifyPipe';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    SearchComponent,
    VerifyEmailComponent,
    TermsConditionComponent,
    imageDetailsComponent,
    SubcriptionComponent,
    PurchaseComponent,
    FaqsComponent,
    PaginatorComponent,
    SlugifyPipe


  ],
    imports: [
        BrowserModule,
        UserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        MatProgressBarModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot({
            maxOpened: 2,
        }),
        NgxSpinnerModule,
        MatProgressSpinnerModule,
        NgxDropzoneModule,
        InfiniteScrollModule
    ],
  providers: [

    SlugifyPipe,
    FormBuilder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule { }
