
<section class="pt-5 bg-white">
  <ngx-spinner type="ball-atom"></ngx-spinner>
  <div class="container">
    <div class="row pt-5">
      <div class="col-lg-6 col-sm-12">
        <div>
          <h3 class="font48 font-bold">
            Dashboard
          </h3>
          <p>Here you can manage uploads and earnings</p>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12" *ngIf="dashboardData">
        <ul class="nav nav-pills mb-3" id="tab-navs" role="tablist">
          <li class="nav-item" >
            <a
              [ngClass]="{'active' : dashboardData.duration == 'today'}"
              (click)="getDashboardData('today')"
              class="nav-link"
              id="pills-today-tab"
              data-toggle="pill"
              href="#pills-today"
              role="tab"
              aria-controls="pills-today"
              aria-selected="true"
            >Today
              <br/>
              <div class="small-line text-center mt-1"></div
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              [ngClass]="{'active' : dashboardData.duration == 'week'}"
              (click)="getDashboardData('week')"
              class="nav-link"
              id="pills-week-tab"
              data-toggle="pill"
              href="#pills-week"
              role="tab"
              aria-controls="pills-week"
              aria-selected="false"
            >Week
              <br/>
              <div class="small-line text-center mt-1"></div
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              [ngClass]="{'active' : dashboardData.duration == 'month'}"
              (click)="getDashboardData('month')"
              class="nav-link"
              id="pills-month-tab"
              data-toggle="pill"
              href="#pills-month"
              role="tab"
              aria-controls="pills-month"
              aria-selected="false"
            >Month
              <br/>
              <div class="small-line text-center mt-1"></div
              >
            </a>
          </li>
          <li class="nav-item">
            <a
              [ngClass]="{'active' : dashboardData.duration == 'all'}"
              (click)="getDashboardData('all')"
              class="nav-link"
              id="pills-allTime-tab"
              data-toggle="pill"
              href="#pills-allTime"
              role="tab"
              aria-controls="pills-allTime"
              aria-selected="false"
            >All Time
              <br/>
              <div class="small-line text-center mt-1"></div
              >
            </a>
          </li>
        </ul>
        <div class="tab-content" id="tab-content">
          <div
            class="tab-pane fade show active"
            id="pills-today"
            role="tabpanel"
            aria-labelledby="pills-today-tab"
          >
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-green">
                  <div class="d-flex align-items-center">
                    <div class="mr-4">
                      <img src="assets/img/camera.png" alt=""/>
                    </div>
                    <div>
                      <h3 class="font32 font-bold text-white mb-0" *ngIf="dashboardData">{{dashboardData.contributions.total}}</h3>
                      <h5 class="font14 font-bold text-white">Photos</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 sm-mt">
                <div class="card p-4 bg-red">
                  <div>
                    <h3 class="font32 font-bold text-white mb-0" *ngIf="dashboardData">&#x20A6;{{dashboardData.earning_total}}</h3>
                    <h5 class="font14 font-bold text-white">Today</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12"></div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-week"
            role="tabpanel"
            aria-labelledby="pills-week-tab"
          >
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-green">
                  <div class="d-flex align-items-center">
                    <div class="mr-4">
                      <img src="assets/img/camera.png" alt=""/>
                    </div>
                    <div>
                      <h3 class="font32 font-bold text-white mb-0">{{dashboardData.contributions.total}}</h3>
                      <h5 class="font14 font-bold text-white">Photos</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-red">
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">&#x20A6;{{dashboardData.earning_total}}</h3>
                    <h5 class="font14 font-bold text-white">Today</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12"></div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-month"
            role="tabpanel"
            aria-labelledby="pills-month-tab"
          >
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-green">
                  <div class="d-flex align-items-center">
                    <div class="mr-4">
                      <img src="assets/img/camera.png" alt=""/>
                    </div>
                    <div>
                      <h3 class="font32 font-bold text-white mb-0">{{dashboardData.contributions.total}}</h3>
                      <h5 class="font14 font-bold text-white">Photos</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-red">
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">&#x20A6;{{dashboardData.earning_total}}</h3>
                    <h5 class="font14 font-bold text-white">Today</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12"></div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-allTime"
            role="tabpanel"
            aria-labelledby="pills-allTime-tab"
          >
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-green">
                  <div class="d-flex align-items-center">
                    <div class="mr-4">
                      <img src="assets/img/camera.png" alt=""/>
                    </div>
                    <div>
                      <h3 class="font32 font-bold text-white mb-0">{{dashboardData.contributions.total}}</h3>
                      <h5 class="font14 font-bold text-white">Photos</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="card p-4 bg-red">
                  <div>
                    <h3 class="font32 font-bold text-white mb-0">&#x20A6;{{dashboardData.earning_total}}</h3>
                    <h5 class="font14 font-bold text-white">Today</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ul class="nav nav-pills  pb-2" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a
          (click)="setSection('library')"
          [ngClass]="{'active' : this.section == 'library'}"
          class="nav-link font18 text-grey font-bold"
          id="pills-Photos-tab"
          data-toggle="pill"
          href="#pills-Photos"
          role="tab"
          aria-controls="pills-Photos"
          aria-selected="true"
        >Library<br/>
        </a>
      </li>
      <li class="nav-item">
        <a
          (click)="setSection('contributions')"
          [ngClass]="{'active' : this.section == 'contributions'}"
          class="nav-link font18 text-grey font-bold"
          id="pills-Contributions-tab"
          data-toggle="pill"
          href="#pills-Contributions"
          role="tab"
          aria-controls="pills-Contributions"
          aria-selected="false"
        > Contributions
          <br
          />
        </a>
      </li>


      <li class="nav-item">
        <a
          (click)="setSection('wallet')"
          [ngClass]="{'active' : this.section == 'wallet'}"
          class="nav-link font18 text-grey font-bold"
          id="pills-Settings-tab"
          data-toggle="pill"
          href="#pills-Earnings"
          role="tab"
          aria-controls="pills-Earnings"
          aria-selected="false"
        >Wallet
          <br
          />
        </a>
      </li>
    </ul>
    <mat-progress-bar *ngIf="userService.loading" mode="indeterminate"></mat-progress-bar>
  </div>
</section>
<section class="py-5 container">

  <div class="tab-content" id="pills-tabContent">
    <div
      [ngClass]="{'show active' : this.section == 'library'}"
      class="tab-pane fade"
      id="pills-Photos"
      role="tabpanel"
      aria-labelledby="pills-Photos-tab"
    >

      <div
        class="row align-items-center bg-white sm-reverse border-radius my-4 box-shadow"
      >
        <div
          class="text-green font12 font-bold col-lg-2 col-sm-12 sm-text-center sm-mt sm-mb-2"
        >
          FILTER
        </div>
        <div class="table-search col-lg-7 col-sm-12">
          <input type="text" placeholder="Search" name="librarySearch" (keyup)="library()" [(ngModel)]="librarySearch"/>
        </div>
        <div class="sort col-lg-3 col-sm-12 sm-mt sm-mb-2 sm-text-center">
          <span class="font-bold font12 text-grey mr-3">SORT BY</span>
          <span class="dropdown click">
            <span
              class="font-bold font12 dropdown-toggle"
              id="dropdownMenuButton"
              data-toggle="dropdown"
            >Date <i class="fa fa-chevron-down"></i
            ></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item click line-under font12">Date</a>
              <a class="dropdown-item click  font12">Date</a>
            </div>
          </span>
        </div>
      </div>
      <div class="table-responsive " *ngIf="dashboardData">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Contributor</th>
            <th scope="col">Cost (&#x20A6;)</th>
            <th scope="col">Date Downloaded</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let image of dashboardData.library.data">
            <td>{{image.name}}</td>
            <td>{{image.user.firstname}} {{image.user.surname}}</td>
            <td>{{image.cost}}</td>
            <td>{{image.created_at}}</td>
            <td>
<!--                <span-->
<!--                  class="mx-2"-->
<!--                  data-toggle="modal"-->
<!--                  data-target="#shareModal"-->
<!--                >-->
<!--                  <img class="h-25" src="assets/img/share.png" alt=""/>-->
<!--                </span>-->
<!--              <span-->
<!--                class="mx-2"-->
<!--                data-toggle="modal"-->
<!--                data-target="#deleteModal"-->
<!--              >-->
<!--                  <img class="h-25" src="assets/img/delete.png" alt=""/>-->
<!--                </span>-->
              <span class="mx-2" (click)="downloadFullImage(image)">
                  <img class="h-25" src="assets/img/arrow-down.png" alt=""/>
                </span>
            </td>

          </tr>
          </tbody>
        </table>

      </div>

      <!-- pagination -->
<!--      <div class="list-nav text-center mt-5">-->
<!--        <span><</span>-->
<!--        <span>1</span>-->
<!--        <span class="active">2</span>-->
<!--        <span>3</span>-->
<!--        <span>4</span>-->
<!--        <span>5</span>-->
<!--        <span>></span>-->
<!--      </div>-->
    </div>

    <div
      [ngClass]="{'show active' : this.section == 'contributions'}"
      class="tab-pane fade"
      id="pills-Contributions"
      role="tabpanel"
      aria-labelledby="pills-Contributions-tab"
    >

      <div
        class="row align-items-center bg-white sm-reverse border-radius mb-4 box-shadow"
      >
        <div
          class="text-green font12 font-bold col-lg-2 col-sm-12 sm-text-center sm-mt sm-mb-2"
        >
          FILTER
        </div>
        <div class="table-search col-lg-7 col-sm-12">

          <input type="text" placeholder="Search" name="contributionSearch" (keyup)="contributions()" [(ngModel)]="contributionSearch"/>
        </div>
        <div class="sort col-lg-3 col-sm-12 sm-mt sm-mb-2 sm-text-center">
          <span class="font-bold font12 text-grey mr-3">BY</span>
          <span class="dropdown click">
            <span
              class="font-bold font12 dropdown-toggle text-uppercase"
              id="contributionsDropdownMenuButton"
              data-toggle="dropdown"
            >{{contributionStatus}} <i class="fa fa-chevron-down"></i
            ></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a (click)="setContributionStatus('all')" class="dropdown-item click font12">All</a>
              <a (click)="setContributionStatus('published')" class="dropdown-item click font12">Published</a>
              <a (click)="setContributionStatus('un-published')" class="dropdown-item click  font12">Un-Published</a>
            </div>
          </span>
        </div>
      </div>

      <!--  notice section -->
      <div class="p-3 w-90 font14 border-radius border">
        <b>Note:</b> After uploading, Please edit your image and add extra information about your image to publish your photo for sale.
        <a href="/faqs">Learn more.</a>
      </div>
      <!-- end notice -->

      <div class="text-right mb-2 mt-2">
        <button class="font-bold px-4 py-2 bg-white btn box-shadow" routerLink='/user/upload'>
          Upload Images
        </button>

        <button class="font-bold px-4 py-2 ml-2 btn btn-danger box-shadow"
                data-toggle="modal"
                data-target="#deleteSelectedModal"
                [disabled]="userService.loading"
        >
           Delete Selected
        </button>


      </div>

      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr  >
            <th>
              <label class="check-container pointer">
                <input type="checkbox"  (change)="selectAllImids('contributions',$event)" />
                <span class="checkmark"></span>
              </label>

<!--              <input class="pointer" type="checkbox" (change)="selectAllImids('contributions')"></th>-->
            <th>Name</th>
            <th>Preview</th>
            <th>Status</th>
            <th>Total Earning</th>
            <th>Category</th>
            <th>No of downloads</th>
            <th>Date Added</th>
            <th></th>
          </tr>
          </thead>
          <tbody *ngIf="dashboardData">

          <tr  *ngFor="let image of contributionsData; let i = index">
            <td>
              <label class="check-container pointer">
                <input type="checkbox" [checked]="image.selected" (change)="selectContributionImid(i)" />
                <span class="checkmark" ></span>
              </label>
<!--              <input class="pointer" type="checkbox" [checked]="image.selected" (change)="selectContributionImid(i)">-->
            </td>
            <td><a [routerLink]="['/user/image/',image.imid]">
              {{image.name}}
            </a>
            </td>
            <td><img [src]="image.preview" height="50"> </td>
            <td>
              <label *ngIf="image.status == 'approved'" class="badge badge-success">{{image.status}}</label>
              <label *ngIf="image.status == 'rejected'" class="badge badge-danger">{{image.status}}</label>
              <label *ngIf="image.status == 'pending'" class="badge badge-warning">{{image.status}}</label>
              <br>
              <span *ngIf="image.is_published == 1" class="badge badge-success">Published</span>
              <span *ngIf="image.is_published == 0" class="badge badge-danger">Un-Published</span>
            </td>

            <td *ngIf="image.earnings">{{image.earnings.amount}}</td>
            <td *ngIf="!image.earnings">0</td>
            <td>{{image.category}}</td>
            <td>{{image.download_count}}</td>
            <td>{{image.created_at}}</td>
            <td>
              <span
                class="mx-2 pointer"
                data-toggle="modal"
                data-target="#deleteModal"
              >
                  <img class="h-25" src="assets/img/delete.png" (click)="selectImage(image)" alt=""/>
                </span>
              <span>
                <a [routerLink]="['/user/image/',image.imid]">
                  <img class="h-25" src="assets/img/edit.png" title="Edit Image"  alt=""/>
                </a>
              </span>
              <span class="mx-2">
                  <img class="h-25" src="assets/img/arrow-down.png" alt=""/>
                </span>
            </td>
          </tr>

          </tbody>
        </table>
      </div>

      <!--      <div class="list-nav text-center mt-5">-->
<!--        <span><</span>-->
<!--        <span>1</span>-->
<!--        <span class="active">2</span>-->
<!--        <span>3</span>-->
<!--        <span>4</span>-->
<!--        <span>5</span>-->
<!--        <span>></span>-->
<!--      </div>-->

    </div>

    <div
      [ngClass]="{'show active' : this.section == 'wallet'}"
      class="tab-pane fade"
      id="pills-Earnings"
      role="tabpanel"
      aria-labelledby="pills-Earnings-tab"
    >
      <div class="row">
        <div class="col-lg-4 col-md-5 col-sm-12">
          <div>
            <div
              class="bg-black font-bold text-white p-4 d-flex justify-content-between border-radius box-shadow align-items-center"
            >
              <div>
                <p class="font14 mb-0">
                  Wallet Balance
                </p>
                <h5 class="font32">{{userService.user.balance}}</h5>
              </div>
              <div>
                <img src="assets/img/user.png" alt=""/>
              </div>
            </div>
            <div class="px-4 py-5 bg-white border-radius mt-5">
              <h3 class="font18 font-bold">Withdraw Funds</h3>
              <p class="my-4 font-light font14">
                Withdraw your earnings from your Orbzone account
              </p>
              <form *ngIf="userService.user">
                <mat-form-field>
                  <mat-label >Choose Account</mat-label>
                  <mat-select [(ngModel)]="accountIndex" name="accountIndex" >
                    <mat-option *ngFor="let account of userService.user.accounts;let i = index" [value]="i"  (onSelectionChange)="selectAccount(i)">
                      {{account.account_number}} -  {{account.account_bank}} ({{account.account_name}})
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="my-3">
                  <label class="font14 font-bold" for="amount"
                  >Amount to withdraw
                  </label
                  >
                  <input
                    [(ngModel)]="amountToWithdraw"
                    name="amount"
                    type="tel"
                    id="amount"
                    class="form-control common-input"
                    placeholder="0"
                  />
                </div>
                <div class="my-3">
                  <label class="font14 font-bold" for="password"
                  >Password
                  </label
                  >
                  <input
                    [(ngModel)]="password"
                    name="password"
                    type="password"
                    id="password"
                    class="form-control common-input"
                    placeholder="******"
                  />
                </div>
                <button *ngIf="accountIndex == null"  disabled  class="bg-red btn text-white w-100 mt-4">
                  Withdraw
                </button>
                <button *ngIf="accountIndex != null" data-toggle="modal" data-target="#withdrawModal" class="bg-red btn text-white w-100 mt-4">
                  Withdraw
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-7 col-sm-12">
          <div>
            <div class="p-4 bg-white box-shadow border-radius">
              <div class="row">
                <div class="col-lg-7 col-sm-12">
                  <h3 class="font18 font-bold">Withdrawals</h3>
                  <p class="text-grey font14 font-light py-4">
                    This is where the money you make on Orbzone is stored. You can withdraw
                    to your added bank accounts.
                  </p>
                 </div>

                <div class="col-lg-1 col-sm-12"></div>
                <div class="col-lg-4 col-sm-12 mt-auto">
                  <button
                    routerLink="/user/profile"
                    class="bg-white btn text-green border-green w-100 mt-4"
                  >
                    Add Bank Account
                  </button>
                </div>
              </div>
            </div>
            <div>
              <h3 class="font24 font-bold my-5">Withdrawal History</h3>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Reference</th>
                    <th>Bank Account</th>
                    <th>Amount (&#x20A6;)</th>
                    <th>Balance Before</th>
                    <th>Balance After</th>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let withdrawal of userService.user.withdrawals">
                    <td>{{withdrawal.reference}}</td>
                    <td>{{withdrawal.account.account_number}}({{withdrawal.account.account_bank}})</td>
                    <td>{{withdrawal.amount}}</td>
                    <td>{{withdrawal.balance_before}}</td>
                    <td>{{withdrawal.balance_after}}</td>
                    <td>{{withdrawal.description}}</td>
                    <td>{{withdrawal.created_at}}</td>
                    <td>{{withdrawal.status}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>

<!--              <div class="list-nav text-center mt-5">-->
<!--                <span><</span>-->
<!--                <span>1</span>-->
<!--                <span class="active">2</span>-->
<!--                <span>3</span>-->
<!--                <span>4</span>-->
<!--                <span>5</span>-->
<!--                <span>></span>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="pills-Settings"
      role="tabpanel"
      aria-labelledby="pills-Settings-tab"
    >
      <div class="row">

        <!-- profile left side bar  -->
<!--        <div class="col-lg-4 col-sm-12">-->
<!--          <div-->
<!--            class="bg-black pb-3 pt-4 text-center"-->
<!--            style="border-radius:6px 6px 0px 0px; "-->
<!--          >-->
<!--            <div class="profile-img mx-auto">-->
<!--              <img src="assets/img/Oval.png" alt=""/>-->
<!--            </div>-->
<!--            <p class="mt-2 font24 font-bold text-white ">Sarah-->
<!--              <br/>-->
<!--              Shaibu-->
<!--            </p>-->
<!--          </div>-->
<!--          <div-->
<!--            class="px-5 py-3 d-flex justify-content-between font-bold font12 text-white"-->
<!--            style="background: #3e3e3e; border-radius: 0px 0px 6px 6px;"-->
<!--          >-->
<!--            <span>EDIT PROFILE</span>-->
<!--            <span>LOG OUT</span>-->
<!--          </div>-->
<!--          <div class="px-4 bg-white border-radius box-shadow pt-4 text-center pb-4 mt-4">-->
<!--            <div class="font14 text-grey font-light pb-3">SHARE PROFILE</div>-->
<!--            <div class="d-flex justify-content-between">-->
<!--              <img src="assets/img/Facebook.png" alt=""/>-->
<!--              <img src="assets/img/Twitter.png" alt=""/>-->
<!--              <img src="assets/img/Tumblr.png" alt=""/>-->
<!--              <img src="assets/img/GooglePlus.png" alt=""/>-->
<!--            </div>-->
<!--            <button class="bg-green text-white w-100 btn mt-5 mb-4">Share</button>-->
<!--          </div>-->
<!--          <button class="bg-red btn text-white w-100 my-4">-->
<!--            Update Password-->
<!--          </button>-->
<!--          <p class="mt-3 font12 text-underline">Cancel subscription</p>-->
<!--        </div>-->
<!--        <div class="col-lg-8 col-sm-12"></div>-->
<!--        -->
        <!-- end profile left sidebar -->
      </div>
    </div>
  </div>
</section>

<!-- delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header px-4">
        <h5 class="modal-title font20" id="deleteModalLabel">Confirm Delete</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-5">
        <p class="font-light text-center">
          Are you sure you want to delete this Picture? Click the ‘No’ button to
          cancel this action
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn bg-white text-red border-red" (click)="deselectImage()" data-dismiss="modal">
          No
        </button>
        <button class="btn bg-red text-white" data-dismiss="modal" (click)="deleteImage()">Yes</button>
      </div>
    </div>
  </div>
</div>
<!-- delete Selected Modal -->
<div
  class="modal fade"
  id="deleteSelectedModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header px-4">
        <h5 class="modal-title font20" id="deleteSelectedModalLabel">Confirm Delete</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-5">
        <p class="font-light text-center">
          Are you sure you want to delete all selected Pictures? Click the ‘No’ button to
          cancel this action
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button class="btn bg-white text-red border-red"  data-dismiss="modal">
          No
        </button>
        <button class="btn bg-red text-white" data-dismiss="modal" (click)="deleteSelectedImages()">Yes</button>
      </div>
    </div>
  </div>
</div>

<!-- share modal -->
<div
  class="modal fade"
  id="shareModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="shareModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header px-4">
        <h5 class="modal-title font20" id="shareModalLabel">Share Photo</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-5">
        <div class="d-flex justify-content-between">
          <img src="assets/img/Facebook.png" alt=""/>
          <img src="assets/img/Twitter.png" alt=""/>
          <img src="assets/img/Tumblr.png" alt=""/>
          <img src="assets/img/GooglePlus.png" alt=""/>
        </div>
        <button class="bg-green text-white w-100 btn mt-5 mb-4">Share</button>
      </div>
    </div>
  </div>
</div>

<!-- withdrawal modal -->


<!--Withdraw Modal -->
<div  class="modal fade" id="withdrawModal" tabindex="-1" role="dialog" aria-labelledby="withdrawModalLabel" aria-hidden="true">
  <div *ngIf="userService.user && accountIndex != null" class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header px-5">
        <h5 class="modal-title" id="withdrawModalLabel">Confirm Withdrawal</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-7" *ngIf="userService.user.accounts.length > 0" >
        <p class="font14 font-light">
          Your money will be deposited into the account below.
        </p>
        <p class="font14" >
          {{userService.user.accounts[accountIndex].account_number}}
        </p>
        <p class="d-flex justify-content-between">
          <span>Amount </span>
          <span>&#x20A6;{{amountToWithdraw}}</span>
        </p>
        <p class="d-flex justify-content-between">
          <span>Bank Transfer Charge </span>
          <span>&#x20A6;50</span>
        </p>
        <p class="d-flex justify-content-between">
          <span>Bank</span>
          <span>{{userService.user.accounts[accountIndex].account_bank}}</span>
        </p>
        <textarea class="form-control" name="description" [(ngModel)]="description" placeholder="Add optional note" maxlength="100"></textarea>
        <p class="line-top font12 font-light mb-5 pt-2">
          By clicking confirm withdrawal, you agree to our terms of service.
        </p>
        <button data-dismiss="modal" class="btn bg-red w-100 text-white border-radius" (click)="widthdraw()" >Confirm Withdrawal</button>
      </div>
    </div>
  </div>
</div>
<!-- end modal -->
