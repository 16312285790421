import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {


  selectedImids:any[] = [];
  accountIndex;
  selectedImage;
  withdrawalHistory = [];
  dashboardData =  null;
  duration: "month";
  librarySearch = "";
  contributionSearch = "";
  amountToWithdraw = 0;
  password = '';
  description = '';
  section = '';
  contributionStatus = 'All';

  contributionsData;
  libraryData;

  constructor(
    public userService:UserService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public route : ActivatedRoute,
    public router : Router

  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(queryParams => {
      this.section = queryParams["section"];
    });

    if(this.section == '' || this.section == null) this.section = 'library';

    this.userService.getUpdatedProfile();
    this.getDashboardData('today');

  }


  getDashboardData(duration){
    this.spinner.show();
    this.duration = duration;

    let data = {
      "duration" : this.duration
    };

    this.userService.dashboard(data).subscribe((success:any)=>{
      this.spinner.hide();
      this.userService.loading = false;

      this.dashboardData = success.data;
      this.contributionsData = success.data.contributions.data;
      this.libraryData = success.data.library.data;

      if(this.userService.user.withdrawals.length > 0)
        this.accountIndex = 0;


    },(error)=>{
      this.userService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }


  setContributionStatus(status){
    this.contributionStatus = status;
    this.contributions();
  }

  library(){
    // if(this.librarySearch.length < 2) return;

    this.userService.loading = true;

    let data = {
      "name" : this.librarySearch
    };

    this.userService.library(data).subscribe((success:any)=>{

      this.userService.loading = false;
      this.dashboardData.library.data = success.data.images.data;

    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });

  }


  contributions(){
    // if(this.librarySearch.length < 2) return;

    this.userService.loading = true;

    let data = {
      "name" : this.contributionSearch,
      "status" : this.contributionStatus
    };

    this.userService.contributions(data).subscribe((success:any)=>{
      this.userService.loading = false;

      this.contributionsData = success.data.images.data;

    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });

  }

  deselectImage(){
    this.selectedImage = null;
  }
  selectImage(image){
    this.selectedImage = image;
  }

  selectAccount(index){
    this.accountIndex = index;
    console.log(this.accountIndex);
  }

  deleteSelectedImages(){

    this.userService.loading = true;

    for(let i = 0; i < this.contributionsData.length; i++){
      if(this.contributionsData[i].selected) this.selectedImids.push(this.contributionsData[i].imid);
    }

    let data = {
      "imids" : this.selectedImids
    };

    this.userService.deleteImages(data).subscribe((success:any)=>{
      this.userService.loading = false;

      this.selectedImids = [];

      //deselect all
      for(let i = 0; i < this.contributionsData.length; i++){
        this.contributionsData[i].selected = false;
      }


      this.toastr.success(success.message);
      this.getDashboardData(this.duration);

    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });


  }

  deleteImage(){

    this.userService.loading = true;

    let data = {
      "imid" : this.selectedImage.imid
    };

    this.userService.deleteImage(data).subscribe((success:any)=>{
      this.userService.loading = false;

      this.toastr.success(success.message);
      this.getDashboardData(this.duration);

    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });

  }

  downloadFullImage(image){
    this.selectImage(image);

    this.userService.loading = true;

    let data = {
      "imid" : this.selectedImage.imid
    };


    this.userService.downloadFullImage(data).subscribe((success:any)=>{
      this.userService.loading = false;

      var anchor = document.createElement('a');
      anchor.href = success.data.image.download_link;
      anchor.target = '_blank';
      anchor.click();
      this.getDashboardData(this.duration);

    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });

  }



  withdrawals(){
    this.userService.loading = true;

    let data = {
    };

    this.userService.withdrawals(data).subscribe((success:any)=>{
      this.userService.loading = false;

      this.withdrawalHistory = success.data.withdrawals.data;
      this.userService.getUpdatedProfile();
    },(error)=>{

      this.userService.loading = false;
      this.toastr.error(error.error.message);
    });

  }

  widthdraw(){
    this.userService.loading = true;

    let data = {
      amount: this.amountToWithdraw,
      accountid: this.userService.user.accounts[this.accountIndex].accountid,
      password: this.password,
      description: this.description
    };
    this.spinner.show();

    this.userService.withdraw(data).subscribe((success:any)=>{
      this.userService.loading = false;
      this.userService.getUpdatedProfile();
      this.getDashboardData(this.duration);
      this.spinner.hide();
      this.toastr.success(success.message);
      this.withdrawals();

      this.password = '';
      this.description = '';

    },(error)=>{

      this.userService.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  setSection(section){
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {section : section},
        queryParamsHandling: 'merge',
        replaceUrl : true
      });
  }

  selectContributionImid(index){
    if(this.contributionsData[index].selected == null) this.contributionsData[index].selected = false;
    this.contributionsData[index].selected = !this.contributionsData[index].selected;

  }

  selectAllImids(type,event){
    if(type == 'contributions'){

      for(let i = 0; i < this.contributionsData.length; i++){
        this.contributionsData[i].selected = event.target.checked;

      }

    }
  }


}
