<app-navbar></app-navbar>
<section>
  <h3 class="py-5 font64 font-bold container text-center">
    Thousands of Creative Images. Unlimited Downloads.
  </h3>
  <section class="px-4 sm-p-none hidden pb-5">
    <div class="price-cards">
      <div class="row">
        <div class="col-lg-4 col-sm-12 sm-border">
          <div class="p-4 pt-5">
            <h3 class="font24 font-bold">Individuals</h3>
            <p class="text-grey py-3">The ultimate creative subscription</p>
            <p class="pb-3 text-grey">
              From <span class="font-bold font24">N5000/m</span>
            </p>
            <button
              class="btn form-control bg-red text-white font-bold "
              routerLink="/purchase"
            >
              Start now
            </button>
            <div class="text-grey mt-4">
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Thousands
                of creative digital assets
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />100 photos
                daily limit
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Courses &
                tutorials
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Simple
                commercial licensing
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Cancel any
                time
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 border-x sm-border">
          <div class="p-4 pt-5">
            <h3 class="font24 font-bold">Teams</h3>
            <p class="text-grey py-3">Save as you add members</p>
            <p class="pb-3 text-grey">
              From <span class="font-bold font24">N5000/m</span> per member
            </p>
            <button
              class="btn form-control bg-red text-white font-bold "
              routerLink="/purchase"
            >
              Start now
            </button>
            <div class="text-grey mt-4">
              <p class="text-green font-bold pb-3 mb-5 line-under">
                Everything in Individuals, plus:
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />200 photos
                daily limit
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Simple
                commercial licensing
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Cancel any
                time
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 sm-border">
          <div class="p-4 pt-5">
            <h3 class="font24 font-bold">Enterprise</h3>
            <p class="text-grey py-3">Custom solutions for your organization</p>
            <p class="pb-3 text-grey font-bold font24">Custom pricing</p>
            <button
              class="btn form-control bg-red text-white font-bold "
              routerLink="/purchase"
            >
              Enquire about Enterprise
            </button>
            <div class="text-grey mt-4">
              <p class="text-green font-bold pb-3 mb-5 line-under">
                Everything in Individuals, plus:
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Unlimited
                photos daily limit
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Simple
                commercial licensing
              </p>
              <p>
                <img src="assets/img/mark.png" alt="" class="mr-2" />Cancel any
                time
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white px-4 py-5">
    <h3 class="font-bold font24 text-center">Easy and secure payment</h3>
    <div class="row py-4">
      <div class="col-lg-4 col-sm-12">
        <div class="m-2 p-4 border">
          <img src="assets/img/credit-card.png" alt="" />
          <h3 class="font18 font-bold my-3">Trusted payment methods</h3>
          <p class="font12 font-bold text-grey">
            Pay with Visa, Mastercard or Bank Transfer
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 sm-mt">
        <div class="m-2 p-4 border">
          <img src="assets/img/shield.png" alt="" />
          <h3 class="font18 font-bold my-3">Trusted payment methods</h3>
          <p class="font12 font-bold text-grey">
            Pay with Visa, Mastercard or Bank Transfer
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-12 sm-mt">
        <div class="m-2 p-4 border">
          <img src="assets/img/lock.png" alt="" />
          <h3 class="font18 font-bold my-3">Trusted payment methods</h3>
          <p class="font12 font-bold text-grey">
            Pay with Visa, Mastercard or Bank Transfer
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="py-5 container">
    <div class="row">
      <div class="col-lg-5 col-sm-12">
        <h3 class="font36 font-bold">F.A.Q</h3>
        <div id="accordion">
          <div id="headingOne" class='my-4'>
            <h5
              class="mb-0 font-bold font20 click"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is Orbzone?
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show "
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
          <p class="text-grey">
            Orbzone is a service that provides unlimited downloads of hundreds
            of thousands of image assets
          </p>
        </div>
          <div id="headingTwo" class='my-4'>
            <h5
              class="mb-0 collapsed font20 font-bold click"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Can I cancel and upgrade anytime ?
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
          <p class="text-grey">
            Orbzone is a service that provides unlimited downloads of hundreds
            of thousands of image assets
          </p>
          </div>
        </div>
      </div>
      <div class="col-lg-1 col-sm-12"></div>
      <div class="col-lg-6 col-sm-12 sm-mt">
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="m-2 p-4 bg-white">
              <img src="assets/img/phone.png" alt="" />
              <h3 class="font21 font-bold my-3">Call Us</h3>
              <p class="font13 font-bold text-grey">
                Place a call to our contact centre for quick answers to all your
                questions.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 sm-mt">
            <div class="m-2 p-4 bg-white">
              <img src="assets/img/email.png" alt="" />
              <h3 class="font21 font-bold my-3">Send an Email</h3>
              <p class="font13 font-bold text-grey">
                If you have further enquiries send us an email to info
                kakedbar.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
