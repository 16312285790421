import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {User} from '../../models/User';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  public env  =  'local';
  public url;
  public inlinePaymentUrl;
  public version = 'v1';
  public user:User;
  public isLoggedIn = false;
  public authToken = '';
  public loading = false;
  public nextUrl;
  public publicKey = '';

  constructor(public http: HttpClient,public router: Router) {
    this.setup();
  }

  setup() {
   this.url = environment.apiUrl + this.version + '/';
   this.inlinePaymentUrl =  environment.inlinePaymentUrl;
   this.publicKey =  environment.publicKey;

   this.isUserLoggedIn();

  }

  setUser(user){
    this.user = user;
    this.isLoggedIn = true;
    window.localStorage.setItem("user",JSON.stringify(user));
    window.localStorage.setItem("token",this.authToken);
  }

  getApiFormattedDate(date){
    let month = date.getMonth() + 1;
    return date.getDate() + '-' + month + '-' + date.getFullYear();
  }

  signOut(){
    this.user = null;
    this.isLoggedIn = false;
    this.router.navigate(['/sign-in'])
    window.localStorage.removeItem("user");
  }

  isUserLoggedIn(){
    this.isLoggedIn = localStorage.getItem("token") !== null;
    let user = localStorage.getItem("user");
    let token = localStorage.getItem("token");

    if( user !==  null){
      user = JSON.parse(user);
      this.authToken = token;
      this.setUser(user);
    }
  }
  signUp(user) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(user);
    return this.http.post(this.url + "sign-up",body,{headers});
  }

  signIn(user) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    let body = JSON.stringify(user);
    return this.http.post(this.url + "sign-in",body,{headers});
  }

  // authorized routes

  dashboard(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "dashboard",body,{headers});
  }

  profile(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    return this.http.get(this.url + "profile",{headers});
  }

  editProfile(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "edit-profile",body,{headers});
  }

  viewImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "view-image",body,{headers});
  }

  likeImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "like-image",body,{headers});
  }

  unlikeImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "unlike-image",body,{headers});
  }

  deleteImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "delete-image",body,{headers});
  }

  deleteImages(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "delete-images",body,{headers});
  }

  resolveAccount(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "resolve-account",body,{headers});
  }

  addBankAccount(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "add-bank-account",body,{headers});
  }

  deleteAccount(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "delete-bank-account",body,{headers});
  }

  startCardAddition() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    return this.http.get(this.url + "start-add-card",{headers});
  }

  categories() {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    return this.http.get(this.url + "categories",{headers});
  }


  finishCardAddition(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "finish-add-card",body,{headers});
  }

  deleteCard(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "delete-card",body,{headers});
  }

  library(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "library",body,{headers});
  }

  contributions(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "contributions",body,{headers});
  }

  searchForImages(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "search-for-images",body,{headers});
  }

  publicImageDetails(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "public-image-details",body,{headers});
  }

  imageDetails(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "image-details",body,{headers});
  }

  similarImages(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "similar-images",body,{headers});
  }

  buyImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "buy-image",body,{headers});
  }

  downloadFullImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "download-full-image",body,{headers});
  }

  withdraw(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "withdraw",body,{headers});
  }

  withdrawals(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "withdrawals",body,{headers});
  }

  updateImage(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "update-image",body,{headers});
  }

  startForgotPassword(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "start-forgot-password",body,{headers});
  }

  confirmPhoneCode(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "confirm-phone-code",body,{headers});
  }

  resendPhoneCode(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "resend-phone-code",body,{headers});
  }

  finishForgotPassword(data) {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization",  "Bearer  " +  this.authToken);
    let body = JSON.stringify(data);
    return this.http.post(this.url + "finish-forgot-password",body,{headers});
  }


  addImage(formData){
    let headers = new HttpHeaders()
      .set("Authorization",  "Bearer  " +  this.authToken);
    return this.http.post<any>(this.url + "add-image", formData,{headers});
  }

  saveProfileImage(formData){
    let headers = new HttpHeaders()
      .set("Authorization",  "Bearer  " +  this.authToken);
    return this.http.post<any>(this.url + "save-profile-image", formData,{headers});
  }

  getUpdatedProfile(){
    let data = {

    };
    this.profile(data).subscribe((success:any)=>{
      this.user = success.data.user;
      this.user.cards = success.data.cards;
      this.user.accounts = success.data.accounts;
      this.user.withdrawals = success.data.withdrawals.data;

    });

  }

  handleAuthError(error){
    console.log(error);
    if(error.status == 401){
      this.signOut();
    }
  }



}


