<header>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="assets/img/logo-white.png" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <i class="fa fa-bars btn text-white font20"></i>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto text-capitalize">
          <li class="nav-item">
            <a class="nav-link text-bold mr-4" routerLink="/search">Browse</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-bold mr-5"routerLink="/user/upload">Upload Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-bold mr-5"  *ngIf="!userService.user" routerLink="/sign-in">Sign In</a>
            <a class="nav-link text-bold mr-5"  *ngIf="userService.user" routerLink="/user">Dashboard</a>
          </li>
          <li class="nav-item ml-5" routerLink='/user/profile' *ngIf="userService.user">
            <img class="profile-icon" *ngIf="userService.user.image" [src]="userService.user.image" />
            <img class="profile-icon" *ngIf="!userService.user.image" src="assets/img/avatar.png" />
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="header-text container">
    <h2 class="font64 font-bold text-white mb-3">
      The best African photos <br />shared by talented creators.
    </h2>
    <div class="p-r mb-1 input-div">
      <input type="text" [(ngModel)]="search" placeholder="Search for photos" (keyup.enter)="searchForItem()" />
      <i class="fa fa-search text-grey input-icon" routerLink='/search'></i>
    </div>
    <span class="font14 text-white"
      >Suggested: business, nature, wildlife, petroleum, happy</span
    >
  </div>
</header>

<section class="my-5 container">
  <h3 class="font20 font-bold text-black mb-3">Collections</h3>
  <div class="row">

    <div *ngFor="let category of categories" class="col-lg-4 col-md-12 my-2 sm-mt browse-image">
      <a [href]="'search?search=' + category.name">
        <div class="p-r">
          <img [src]="category.image" class="w-100" alt="" />
          <div class="img-overlay2 text-center">
            <p class="font-bold font20 text-white">{{category.name}}</p>
          </div>
        </div>

      </a>
    </div>

    <div class="col-lg-4 col-sm-12 sm-mt browse-image">
      <a href="search?search=nature">
        <div class="p-r">
          <img src="assets/img/collections2.jpg" class="w-100" alt="" />
          <div class="img-overlay2 text-center">
            <p class="font-bold font20 text-white">Nature</p>
          </div>
        </div>
      </a>
    </div>
    <div class="col-lg-4 col-sm-12 sm-mt browse-image">
      <a href="search?search=business">

        <div class="p-r">
          <img src="assets/img/Rectangle12.png" class="w-100" alt="" />
          <div class="img-overlay2 text-center">
            <p class="font-bold font20 text-white">Business</p>
          </div>
        </div>
      </a>
    </div>
  </div>
</section>

<section class="my-5 container"
         infiniteScroll
         [fromRoot]="true"
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="searchForImages()"

>
  <ul class="nav nav-pills mb-3 font20 font-bold" id="pills-tab" role="tablist">
<!--    <li class="nav-item">-->
<!--      <a-->
<!--        class="nav-link"-->
<!--        id="pills-one-tab"-->
<!--        data-toggle="pill"-->
<!--        href="#pills-one"-->
<!--        role="tab"-->
<!--        aria-controls="pills-one"-->
<!--        aria-selected="true"-->
<!--        >Trending</a-->
<!--      >-->
<!--    </li>-->

  </ul>

  <div>
      <div class="row hidden"
           infiniteScroll
           [infiniteScrollDistance]="20"
           [infiniteScrollThrottle]="50"
           (scrolled)="loadMore()"
      >
        <div class="col-lg-4 col-sm-12 pointer" *ngFor="let image of images">
          <div *ngIf="image.name">
            <div class="img-box browse-image my-2"
                 [routerLink]="['image',slugifyPipe.transform(image.name),image.imid]"
            >
              <img [src]="image.preview" class="w-100" alt="" />
            </div>
          </div>
        </div>

      </div>
    </div>

</section>

<!--<section class="my-5 container">-->
<!--  <h3 class="font20 font-bold text-black mb-3">Featured Contributors</h3>-->
<!--  <div class="row">-->
<!--    <div class="col-lg-4 col-sm-12 sm-mt">-->
<!--      <div class="p-r">-->
<!--        <img src="assets/img/Rectangle2.png" class="w-100" alt="" />-->
<!--        <div class="img-overlay1 text-center">-->
<!--          <img src="assets/img/Ellipse6.png" alt="" />-->
<!--          <p class="font-bold font20 text-white">Nene Kimoji</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-4 col-sm-12 sm-mt">-->
<!--      <div class="p-r">-->
<!--        <img src="assets/img/Rectangle10.png" class="w-100" alt="" />-->
<!--        <div class="img-overlay1 text-center">-->
<!--          <img src="assets/img/Ellipse6.png" alt="" />-->
<!--          <p class="font-bold font20 text-white">Irene Shaibu</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-4 col-sm-12 sm-mt">-->
<!--      <div class="p-r">-->
<!--        <img src="assets/img/Rectangle9.png" class="w-100" alt="" />-->
<!--        <div class="img-overlay1 text-center">-->
<!--          <img src="assets/img/Ellipse6.png" alt="" />-->
<!--          <p class="font-bold font20 text-white">Faith Omebije</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->


<section class="my-5 lg-my-6 hidden">
  <div class="row">
    <div class="col-lg-6 col-sm-12 lg-pr-0">
      <div class="bg-blue text-center px-4 py-5" style="height: 375px;">
        <img src="assets/img/logo-white.png" class="mb-4 height-50" />
        <p class="font36 text-white font-bold mb-4">
          View and Use. <br />Photos
        </p>
        <a routerLink="/sign-up" class="font18 text-white font-bold">
          Become a User
        </a>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12 lg-pl-0">
      <div class="bg-blue text-center px-4 py-5" style="height: 375px;">
        <h2 class="mb-4 font36 font-lato-italic bold text-white">
          Contribute<small class="font-lato-italic light font16 ml-2">by</small>
          <img src="assets/img/logo-white-text.png" class="side-logo" />
        </h2>
        <p class="font36 text-white font-lato bold mb-4">
          Contribute to <br />the Orbzone Market
        </p>
        <a routerLink="/contributors" class="font18 text-white font-lato bold">
          Become a Contributor
        </a>
      </div>
    </div>
  </div>
</section>
