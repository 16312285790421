import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../services/user/user.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {Attribute} from '../../models/Attribute';

@Component({
  selector: 'app-client-submit-content',
  templateUrl: './client-submit-content.component.html',
  styleUrls: ['./client-submit-content.component.css']
})
export class ClientSubmitContentComponent implements OnInit {

  loading:boolean = false;
  image;
  imid;
  tag;

  publish = 'no';
  attributeName;
  attributeValue;
  attributes:Attribute[] = [];
  tags:string[] = [];
  type = 'photo';
  name;
  description;
  category = "choose";
  location = "choose";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public userService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.imid = this.route.snapshot.paramMap.get("imid");
    this.imageDetails();

  }

  addTag(){
    if(this.tag == '' || this.tag == null) {
      this.toastr.error("You can't add an empty tag");
      return;
    }
    this.tags.push(this.tag);
    this.tag = null;
  }

  setPublish(publish){
    if(publish == 'yes'){
      if(this.category == 'choose' || this.category == '' || this.category == null)
        return this.toastr.error("You must choose a category before publishing");
      if(this.description == '' || this.description == null)
        return this.toastr.error("You must enter a description before publishing");
      if(this.tags.length <= 0)
        return this.toastr.error("You must enter at least one tag before publishing");
    }
    this.publish = publish;
  }

  addAttribute(){
    if(this.attributeName == '' || this.attributeName == null || this.attributeValue == '' || this.attributeValue == null) {
      this.toastr.error("You can't add an empty attribute");
      return;
    }

    let attr = new Attribute();
    attr.name = this.attributeName;
    attr.value = this.attributeValue;
    this.attributes.push(attr);
    this.attributeName = null;
    this.attributeValue = null;
  }

  imageDetails(){
    let data = {
      "imid" : this.imid
    };
    this.spinner.show();

    this.userService.publicImageDetails(data).subscribe((success:any)=>{
      this.loading = false;
      this.spinner.hide();

      this.image = success.data.image;
      this.category = this.image.category;
      this.description = this.image.description;
      if(this.image.is_published == 0) this.publish = 'no';
      else this.publish = 'yes';

      this.name = this.image.name;
      if(this.image.attributes !== null) this.attributes = this.image.attributes;
      if(this.image.tags !== null) this.tags = this.image.tags;
      this.description = this.image.description;

      window.scrollTo(0, 0)
    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }

  deleteAttribute(index){
    this.attributes.splice(index,1);
  }

  deleteTag(index){
    this.tags.splice(index,1);
  }

  setType(type){
    this.type = type;
  }


  updateImage(){
    let data = {
      "imid" : this.imid,
      "attributes" : this.attributes,
      "tags" : this.tags,
      "category" : this.category,
      "description" : this.description,
      "name" : this.name,
      "publish" : this.publish,
      "location" : this.location
    };
    this.spinner.show();

    this.userService.updateImage(data).subscribe((success:any)=>{
      this.loading = false;
      this.spinner.hide();

      this.toastr.success(success.message);
      this.router.navigate(["/user/dashboard"],{queryParams : {section : "contributions"}});
    },(error)=>{
      this.loading = false;
      this.spinner.hide();
      this.toastr.error(error.error.message);
    });

  }
}
